<h1 class="text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
    {{data.title}}
  </h1>
  <div class="pb-4 text-sm text-gray-500">
      <div class="px-6 mt-2">
          <fc-text-input placeholder="" label="Name" [(ngModel)]="name"></fc-text-input>
      </div>
  </div>
  <div class="flex pt-4 pb-6 px-6 gap-3">
    <fc-button-secondary class="grow" [mat-dialog-close]="null">Cancel</fc-button-secondary>
    <fc-button-primary class="grow" [mat-dialog-close]="{ name: name }">Create</fc-button-primary>
  </div>