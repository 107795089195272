<div>
    <app-back-button/>
</div>
@if (loading) {
    <app-loader  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"/>
}
@else {
    <div class="mt-6 flex flex-col">
        <div class="flex justify-between">
            <h1 *ngIf="!editing" class="text-3xl font-medium text-gray-900">Add document</h1>
            <h1 *ngIf="editing" class="text-3xl font-medium text-gray-900">Update document: {{document?.name}}</h1>
        </div>
    </div>
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        @if (document) {
            <!-- In future if required, add a dropdown to select version to download -->
            <fc-button-secondary (click)="download(document.versions[0].version)">
                <arrow-down-tray-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                <span>Download</span>
            </fc-button-secondary>
            @if (document.isArchived) {
                <fc-button-secondary (click)="restore()">
                    <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                    <span>Restore</span>
                </fc-button-secondary>
            } 
            @else {
                <fc-button-secondary (click)="archive()">
                    <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                    <span>Archive</span>
                </fc-button-secondary>
            }
        }
    </div>
    <app-document-create-form [documentId]="documentId" [document]="document"/>
}
<div class="p-4 w-[794px] fixed top-[100vh] h-auto ql-editor" #hiddenPdfDiv></div>
