import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddDisciplineModalComponent } from 'src/app/components/disciplines/add-discipline-modal/add-discipline-modal.component';
import { DisciplineGrouped } from 'src/app/models/discipline.model';
import { DisciplineService } from 'src/app/services/discipline.service';

@Component({
  selector: 'app-settings-disciplines',
  templateUrl: './settings-disciplines.component.html',
  styleUrls: ['./settings-disciplines.component.scss']
})
export class SettingsDisciplinesComponent implements OnInit {
  loading: boolean = true;
  disciplines: DisciplineGrouped[] = [];

  constructor(private matDialog: MatDialog, private disciplineService: DisciplineService, private router: Router) {}

  ngOnInit(): void {
    this.disciplineService.getDisciplineGrades().subscribe({
      next: disciplines => {
        this.disciplines = disciplines;
        this.loading = false;
      }
    });

  }

  addDiscipline() {
    const dialogRef = this.matDialog.open(AddDisciplineModalComponent, {
      data: { title: 'Add discipline' },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.name) {
        return;
      }
      
      this.disciplineService.createDiscipline(result.name).subscribe({
        next: id => {
          this.disciplines.push({
            id: id,
            name: result.name,
            grades: []
          })

          this.router.navigateByUrl(`settings/disciplines/${id}`);
        }
      });
    });
  }
}
