import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fc-button-primary',
  templateUrl: './button-primary.component.html',
})
export class ButtonPrimaryComponent {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() variant: string = '';
  @Output() onClick = new EventEmitter<any>();

  get buttonClasses(): string[] {
    const baseClasses = [
      'select-none', 'disabled:bg-gray-200', 'disabled:text-gray-500', 'disabled:border-gray-300', 'bg-primary-600', 'text-white', 'border', 'border-primary-300', 'hover:bg-primary-700', 'focus:ring', 'focus:ring-primary-100', 'focus:outline-0', 'rounded-lg', 'text-center', 'w-full', 'flex', 'items-center'
    ];

    if (this.variant == 'large') {
      baseClasses.push('px-4', 'py-2.5', 'font-semibold');
    }
    else {
      baseClasses.push('text-sm', 'px-3', 'py-2', 'font-medium', 'h-[30px]', 'sm:h-[38px]');
    }
    
    return baseClasses;
  }
}
