import { Injectable } from '@angular/core';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Availability } from '../models/availability.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  #userSubject = new BehaviorSubject<User | undefined>(undefined);

  constructor(private authService: AuthService, private http: HttpClient) { 
  }

  getCurrentUser() {
    if (this.#userSubject.value) {
      return this.#userSubject.asObservable();
    }

    return this.http.get<User>(`${environment.baseUrl}/user/me`).pipe(
      tap(user => this.#userSubject.next(user)),
      switchMap(() => this.#userSubject)
    )
  }

  logout() {
    this.authService.logout({
      logoutParams: { 
        returnTo: window.location.origin
      }
    });
    
    this.#userSubject.next(undefined);
  }

  getUsers() {
    return this.http.get<User[]>(`${environment.baseUrl}/user`)
  }

  getUser(userId: number) {
    return this.http.get<User>(`${environment.baseUrl}/user/${userId}`)
  }

  updateUser(userId: number, user: any) {
    return this.http.put(`${environment.baseUrl}/user/${userId}`, user)
  }

  getAvailability(userId: number) {
    return this.http.get<Availability[]>(`${environment.baseUrl}/user/${userId}/availability`)
  }

  UpdateAvailability(userId: number, availabilities: any[]) {
    return this.http.put(`${environment.baseUrl}/user/${userId}/availability`, availabilities)
  }
}
