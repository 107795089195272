import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Availability } from 'src/app/models/availability.model';
import { Discipline } from 'src/app/models/discipline.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { User } from 'src/app/models/user.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-users-view',
  templateUrl: './settings-users-view.component.html',
  styleUrl: './settings-users-view.component.scss'
})
export class SettingsUsersViewComponent {
  loading: boolean = false;
  user: User | undefined;
  userId: number | undefined;
  userForm: FormGroup;
  availability: Availability[] = [];
  disciplines: Discipline[] = [];
  submitted = false;
  submitting = false;

  toggleOptions = [
    { label: 'Details', value: 'details' },
    { label: 'Availability', value: 'availability' },
  ];
  toggleValue = this.toggleOptions[0].value;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private disciplineService: DisciplineService,
    private toasterService: ToasterService,
    private fb: FormBuilder
  ) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: [''],
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    const id = this.route.snapshot.paramMap.get('id');
  
    if (id) {
      this.userId = +id;
  
      // Combine user and availability fetch requests
      forkJoin({
        user: this.userService.getUser(this.userId),
        availability: this.userService.getAvailability(this.userId),
        disciplines: this.disciplineService.getDisciplines()
      }).subscribe({
        next: ({ user, availability, disciplines }) => {
          this.user = user;
  
          // Patch user data to form
          this.userForm.patchValue(user);
  
          // Pass availabilities to the form component
          this.availability = availability;

          this.disciplines = disciplines;
  
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to load data' });
        }
      });
    }
  }

  onDetailsSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.userForm.valid && this.userId) {
      const updatedUser = { ...this.user, ...this.userForm.value };
      this.userService.updateUser(this.userId, updatedUser).subscribe(() => {
        this.toasterService.addToaster({ type: ToasterType.Success, message: 'User details updated successfully' });
        this.userForm.markAsPristine();
        this.submitted = false;
      });
    }
    this.submitting = false;
  }

  onToggleChange(value: string): void {
    if (value !== 'details') {
      if (this.userForm.dirty) {
        this.userForm.reset(this.user);
      }
    }
  }

}
