import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { Document } from 'src/app/models/document.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DocumentService } from 'src/app/services/document.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-settings-document-create',
  templateUrl: './settings-document-create.component.html',
  styleUrls: ['./settings-document-create.component.scss']
})
export class SettingsDocumentCreateComponent implements OnInit {
  loading: boolean = true;
  editing: boolean = false;
  document: Document | undefined = undefined;
  documentId: number | null = null;

  @ViewChild("hiddenPdfDiv") hiddenPdfDiv!: ElementRef<HTMLCanvasElement>;

  constructor(private route: ActivatedRoute, private router: Router, private documentService: DocumentService, private dialog: MatDialog, private toasterService: ToasterService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.documentId = +id;
      this.editing = true;
      this.documentService.getDocument(+id).subscribe(response => {
        this.document = response;
        this.loading = false;
      })
    }
    else {
      this.loading = false;
    }
  }

  download(version: number) {
    if (this.documentId && this.document) {
      const id = this.documentId;
      const doc = this.document;
      this.documentService.downloadDocument(id, version).subscribe((html) => {
        this.hiddenPdfDiv.nativeElement.classList.remove('invisible');
        this.hiddenPdfDiv.nativeElement.innerHTML = html;
        setTimeout(() => {
          html2canvas(this.hiddenPdfDiv.nativeElement).then(canvas => {
            this.hiddenPdfDiv.nativeElement.classList.add('invisible');
            var imgData = canvas.toDataURL('image/png');
            var imgWidth = 210; 
            var pageHeight = 295;  
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var pdf = new jspdf('p', 'mm');
            var position = 10;
  
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
  
            while (heightLeft >= 0) {
              position += heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }
  
            pdf.save(`${doc.name}_v${version}.pdf`);
          });
        }, 0);
      });
    }
  }

  archive() {
    if (this.documentId) {
      const id = this.documentId;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Archive document template",
          content: "Are you sure you want to archive this document? Archiving this document template will also archive any signed documents by your students.",
          yesText: "Yes, archive it",
          noText: "Cancel",
          yesColourRed: true
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.documentService.archiveDocument(id).subscribe({          
            next: () => {
              if(this.document) {
                this.document.isArchived = true;
              }
              this.toasterService.addToaster({ type: ToasterType.Success, message: 'Document archived' });
            },
            error: () => {
              this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed archive document' });
            }
          });
        }
      });
    }
  }

  restore() {
    if (this.documentId) {
      const id = this.documentId;

      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Restore document template",
          content: "Are you sure you want to restore this document? Restoring this document template will also restore any signed documents by your students.",
          yesText: "Restore",
          noText: "Cancel",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.documentService.restoreDocument(id).subscribe({          
            next: () => {
              if(this.document) {
                this.document.isArchived = false;
              }
              this.toasterService.addToaster({ type: ToasterType.Success, message: 'Document restored' });
            },
            error: () => {
              this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed restore document' });
            }
          });
        }
      });
    }
  }
}
