<ng-container *ngIf="loading">
    <app-loader  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
</ng-container>
<ng-container *ngIf="!loading">
    <div class="w-full mt-4" *ngIf="studentMembership && studentMembership.id">
        <div class="flex mb-4 gap-2 justify-between">
            <div class="flex gap-2 items-center">
                <h1 class="text-xl">{{studentMembership.name}}</h1>
                <div *ngIf="studentMembership.status == 1" class="py-1 px-2 bg-gray-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Pending</div>
                </div>
                <div *ngIf="studentMembership.status == 2" class="py-1 px-2 bg-success-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-success-700 text-[12px] font-medium leading-none">Active</div>
                </div>
                <div *ngIf="studentMembership.status == 3" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-error-700 text-[12px] font-medium leading-none">Inactive</div>
                </div>
                <div *ngIf="studentMembership.status == 4 && studentMembership.cancellationDate" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-error-700 text-[12px] font-medium leading-none">Cancelling: {{studentMembership.cancellationDate | date:'dd-MM-yyyy'}}</div>
                </div>
            </div>
                <button (click)="cancelMembership()" *ngIf="studentMembership.status == 2 && !cancelled" type="button"
                    class="h-12 sm:h-[38px] flex items-center gap-2 text-warning-700 border border-warning-700 bg-warning-25 hover:bg-warning-100 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-4 py-2">
                    <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> 
                    <span class="block sm:hidden">Cancel</span>
                    <span class="hidden sm:block">Cancel Membership</span>
                </button>
                <button (click)="uncancelMembership()" *ngIf="studentMembership.status == 4 && !cancelled" type="button"
                    class="h-12 sm:h-[38px] flex items-center gap-2 text-warning-700 border border-warning-700 bg-warning-25 hover:bg-warning-100 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-4 py-2">
                    <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> 
                    <span class="block sm:hidden">Reactivate</span>
                    <span class="hidden sm:block">Reactivate Membership</span>
                </button>
        </div>
    </div>
    <div *ngFor="let month of invoices">
        <div class="w-full h-6 justify-start items-center gap-2 inline-flex mb-2">
            <div class="grow shrink basis-0 h-px bg-gray-200"></div>
            <div class="text-center text-gray-500 text-base font-bold font-['Inter'] leading-normal">{{month.key}}</div>
            <div class="grow shrink basis-0 h-px bg-gray-200"></div>
        </div>
        <div class="w-full justify-start items-start gap-1.5 inline-flex" *ngFor="let item of month.values; let last = last;">
            <div class="self-stretch pb-1 flex-col justify-start items-center gap-1 inline-flex">
                <div [ngClass]="{'bg-success-400': item.successful, 'bg-error-400': !item.successful, 'w-12 h-12 p-3 rounded-lg shadow border border-gray-200 justify-center items-center inline-flex': true}" class="">
                    <div class="w-6 h-6 relative flex-col justify-start items-start flex text-white" [ngSwitch]="item.successful">
                        <currency-dollar-outline-icon *ngSwitchCase="true"></currency-dollar-outline-icon>
                        <x-mark-outline-icon *ngSwitchCase="false"></x-mark-outline-icon>
                    </div>
                </div>
                <div *ngIf="!last" class="w-0.5 h-full bg-gray-300 rounded-sm"></div>
            </div>
            <div class="grow shrink basis-0 pl-1.5 hover:bg-purple-100 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
            <div class="self-stretch py-1 flex-col justify-start items-start flex">
                <div class="self-stretch text-slate-700 text-sm font-semibold leading-tight">{{item.createdDate|date:'dd-MM-yyyy'}}</div>
                <div class="self-stretch text-slate-600 text-sm font-normal leading-tight">
                    Payment of {{item.amount|currency}} was <span *ngIf="item.successful">successfully</span><span *ngIf="!item.successful">unsuccessfully</span> processed. 
                </div>
            </div>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="studentMembership && studentMembership.invoices.length > 0" class="w-full lg:w-1/2 rounded-lg overflow-x-auto border border-gray-200 bg-white shadow-sm">
        <table class="w-full">
            <tbody class="rounded-lg">
                <ng-container *ngFor="let invoice of studentMembership.invoices; let last = last">
                    <tr class="flex gap-4 items-center {{ !last ? 'border-b border-gray-200' : '' }}">
                        <td class="whitespace-nowrap text-sm font-medium text-gray-900 pl-2.5">
                            {{ invoice.createdDate|date}}
                        </td>
                        <td class="whitespace-nowrap text-sm font-medium text-gray-900">
                            {{ invoice.amount|currency}}
                        </td>
                        <td class="whitespace-nowrap text-sm font-medium text-gray-900">
                            <div *ngIf="invoice.successful" class="py-1 px-2 bg-success-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                <div class="text-center text-success-700 text-[12px] font-medium leading-none">Successful</div>
                            </div>
                            <div *ngIf="!invoice.successful" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                <div class="text-center text-error-700 text-[12px] font-medium leading-none">Failure</div>
                            </div>
                        </td>
                        <td class="whitespace-nowrap text-sm font-medium text-gray-900">
                            {{ invoice.error}}
                        </td>
                        <td class="p-4 flex grow justify-self-end justify-end gap-1 self-stretch text-gray-500">
                            <a class="p-2.5 cursor-pointer" [href]="invoice.stripeUrl" target="_blank">
                                <arrow-down-tray-outline-icon [size]="20" matTooltip="Download"></arrow-down-tray-outline-icon>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div> -->
</ng-container>