import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { StudentMembershipGrid } from 'src/app/models/student-membership.model';

@Component({
  selector: 'app-student-memberships-grid',
  templateUrl: './student-memberships-grid.component.html',
  styleUrl: './student-memberships-grid.component.scss'
})
export class StudentMembershipsGridComponent implements AfterViewInit {
  @Input() memberships: StudentMembershipGrid[] = [];

  columns: GridColumn<StudentMembershipGrid>[] = [];

  @ViewChild('statusTemplate', { static: true }) statusTemplate!: TemplateRef<any>;

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Name',
        field: 'name',
        classes: 'w-1/2'
      },
      {
        header: 'Start date',
        formatter: (row) => row.startDate ? this.localDate(row.startDate) : ''
      },
      {
        header: 'Status',
        template: this.statusTemplate,
        classes: 'text-center w-1/5'
      }

    ];
    this.ref.detectChanges();
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }
}
