<form [formGroup]="settingsAlertsForm" (ngSubmit)="onSubmit()" class="mb-16">
    <div class="border border-gray-200 rounded-lg overflow-hidden mt-8">
        <div class="bg-gray-50 flex justify-between items-center px-4 py-3 cursor-pointer"
            [class.border-b]="abandonmentTabOpen" (click)="toggleAbandonmentTab()">
            <div class="flex flex-col">
                <span class="font-medium text-lg text-gray-500">
                    Abandonment alerts
                </span>
                <span class="text-sm text-gray-500">
                    Receive alerts when students haven’t attended your gym recently.
                </span>
            </div>
            <div>
                <chevron-up-outline-icon *ngIf="!abandonmentTabOpen" class="h-5 w-5"></chevron-up-outline-icon>
                <chevron-down-outline-icon *ngIf="abandonmentTabOpen" class="h-5 w-5"></chevron-down-outline-icon>
            </div>
        </div>
        <div *ngIf="abandonmentTabOpen" formGroupName="abandonmentAlertSettings" class="flex flex-col gap-4 m-6">
            <div class="flex flex-col sm:flex-row pb-5 border-b gap-4 sm:gap-8">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Receive abandonment alerts for:
                </span>
                <div class="flex flex-col gap-2">
                    <div class="flex gap-6 items-center">
                        <input type="checkbox" formControlName="notifyMember"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Members
                        </span>
                    </div>
                    <div class="flex gap-6 items-center">
                        <input type="checkbox" formControlName="notifyGuest"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Guests
                        </span>
                    </div>
                    <div class="flex gap-6 items-center">
                        <input type="checkbox" formControlName="notifyGradedStudent"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Grade managed students
                        </span>
                    </div>
                    <div class="flex gap-6 items-center">
                        <input type="checkbox" formControlName="notifyNonGradedStudent"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Non-grade managed students
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row pb-5 border-b gap-4 sm:gap-8">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Days without visit to raise alert:
                </span>
                <input type="number" formControlName="daysWithoutVisit"
                    class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full basis-1 sm:basis-[80px]" />
            </div>
            <div class="flex flex-col sm:flex-row gap-4 sm:gap-8">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Required student contact details for an alert to be created:
                </span>
                <div class="flex flex-col gap-2">
                    <div class="flex gap-6 items-center">
                        <input type="radio" formControlName="requiredContact" [value]="1"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded-full focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Must have some form of contact (email or mobile)
                        </span>
                    </div>
                    <div class="flex gap-6 items-center">
                        <input type="radio" formControlName="requiredContact" [value]="2"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded-full focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Must have mobile contact recorded
                        </span>
                    </div>
                    <div class="flex gap-6 items-center">
                        <input type="radio" formControlName="requiredContact" [value]="3"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded-full focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Must have email contact recorded
                        </span>
                    </div>
                    <div class="flex gap-6 items-center">
                        <input type="radio" formControlName="requiredContact" [value]="4"
                            class="w-4 h-4 text-gray-50 border-gray-300 rounded-full focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <span class="text-gray-700 font-medium text-sm">
                            Always raise an alert, regardless of contact details
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="this.settingsAlertsForm.dirty">
        <app-save-footer buttonText="Save" [error]="!settingsAlertsForm.valid"></app-save-footer>
    </ng-container>
</form>
