import { Component, EventEmitter, Output } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent {
  user$: Observable<User | undefined>;
  studentPortalUrl = environment.studentPortalUrl;
  checkinUrl = environment.checkinUrl;
  kioskUrl = environment.kioskUrl;

  @Output() collapseClick = new EventEmitter();

  constructor(private userService: UserService) {
    this.user$ = this.userService.getCurrentUser();
  }

  logout() {
    this.userService.logout();
  }
}
