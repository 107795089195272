<div>
    <app-back-button></app-back-button>
</div>
@if (loading) {
    <app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
}
@else {
    <div class="mt-2 flex flex-col">
        <div class="flex justify-between">
            <h1 *ngIf="!editing" class="text-3xl font-medium text-gray-900">Add Membership</h1>
            <h1 *ngIf="editing" class="text-3xl font-medium text-gray-900">Update membership: {{membership?.name}}</h1>
        </div>
    </div>
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        @if (membership) {
            @if (membership.isArchived) {
                <fc-button-secondary (click)="restoreMembership()">
                    <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                    <span>Restore</span>
                </fc-button-secondary>
            } 
            @else {
                <fc-button-secondary (click)="archiveMembership()">
                    <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                    <span>Archive</span>
                </fc-button-secondary>
            }
        }
    </div>
    <app-membership-create-form [membership]="membership" [disciplines]="disciplines"></app-membership-create-form>
}