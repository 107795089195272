<div class="fixed flex flex-col z-40 top-0 left-0 w-16 h-full border-r bg-white">
    <div class="flex flex-col items-center gap-4 mt-2">
        <img class="w-8 h-8" src="/assets/images/logo.svg"/>
    </div>
    <div *ngIf="!isMobile"  class="font-poppins font-medium mt-4 flex-none justify-center items-center gap-2 text-2xl text-primary-700 border-b pb-4 mb-5 flex">
     <div class="flex flex-col items-center gap-4">
       <div class="text-base font-inter font-normal">
        <svg (click)="collapseClick.emit()" data-tooltip-id="expand-tooltip" class="cursor-pointer focus:outline-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.91232 1V19M3.15567 1H16.304C17.3413 1 18.1823 1.89543 18.1823 3V17C18.1823 18.1046 17.3413 19 16.304 19H3.15567C2.1183 19 1.27734 18.1046 1.27734 17V3C1.27734 1.89543 2.1183 1 3.15567 1Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>
      </div>
    </div>
    <div class="grow">
        <div class="flex flex-col justify-between h-full">
            <div>
                <a routerLink="/" class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                    <div class="font-medium flex items-center">
                        <home-outline-icon [size]="20"></home-outline-icon>
                    </div>
                </a>
                <a routerLink="/session/create" class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                    <div class="font-medium flex items-center">
                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 7H1M14 1V4M6 1V4M10 17V11M7 14H13M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z" stroke="#101828" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                            
                        <!-- <calendar-outline-icon [size]="20"></calendar-outline-icon> -->
                    </div>
                </a>
                <a routerLink="/student" class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                    <div class="font-medium flex items-center">
                        <users-outline-icon [size]="20"></users-outline-icon>
                    </div>
                </a>
            </div>
            <div>
                <div class="pb-5 border-b flex flex-col items-center gap-2">
                    <a [href]="studentPortalUrl" class="relative flex rounded-lg px-3.5 py-2 mx-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center">
                            <user-group-outline-icon [size]="20"></user-group-outline-icon>
                        </div>
                    </a>
                    <a [href]="checkinUrl"  class="relative flex rounded-lg px-3.5 py-2 mx-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center">
                            <check-circle-outline-icon [size]="20"></check-circle-outline-icon>
                        </div>
                    </a>
                    <a routerLink="/settings" class="relative flex rounded-lg px-3.5 py-2 mx-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                        <div class="font-medium flex items-center">
                            <cog-6-tooth-outline-icon [size]="20"></cog-6-tooth-outline-icon>
                        </div>
                    </a>
                    <arrow-up-tray-outline-icon (click)="logout()" [size]="20" class="h-5 w-6 heroicon-sw-1.5 cursor-pointer rotate-90 text-gray-500 font-medium"/>
                </div>
                <div *ngIf="user$ | async; let user" class="flex justify-center mb-4 mt-4">
                    <div class="flex flex-col gap-4 items-center">
                        <app-avatar initials="{{user.firstName.charAt(0).toUpperCase()}}{{user.lastName.charAt(0).toUpperCase()}}"></app-avatar>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>