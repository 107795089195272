<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">
      {{ label }}
      <span class="text-primary-700" *ngIf="required">*</span>
    </label>
  
    <div class="relative">
      <div #editorContainer class="text-base"></div>
    </div>
  
    <span *ngIf="helper" class="text-sm text-gray-600 mt-2">{{ helper }}</span>
  </div>
