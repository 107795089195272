import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { StudentHistory } from 'src/app/models/student-history.model';
import { StudentHistoryService } from 'src/app/services/student-history.service';

@Component({
  selector: 'app-student-history',
  templateUrl: './student-history.component.html',
  styleUrls: ['./student-history.component.scss']
})
export class StudentHistoryComponent implements OnInit {
  loading = true;
  history: { key: string, values: any }[] = [];

  constructor(private route: ActivatedRoute, private studentHistoryService: StudentHistoryService) {}

  ngOnInit(): void {
    this.route.parent?.params.subscribe(params => {
      const id = params['id']
      this.studentHistoryService.getStudentHistory(+id).subscribe(response => {
       const groupedHistory = response.reduce((group: any, history) => {
          const month = moment(history.date).format("MMMM, yyyy");
          group[month] = group[month] ?? [];
          group[month].push(history);
          return group;
        }, {});

        const months = Object.keys(groupedHistory);
        const sortedMonths = months.sort((a, b) => moment(b).valueOf() - moment(a).valueOf());

        sortedMonths.forEach(x => this.history.push({ key: x, values: groupedHistory[x]}));
        this.loading = false;
      })
    });
  }
}