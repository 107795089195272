import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddDisciplineModalComponent } from 'src/app/components/disciplines/add-discipline-modal/add-discipline-modal.component';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { DisciplineGrouped, DisciplineGroupedGrade } from 'src/app/models/discipline.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-settings-disciplines-view',
  templateUrl: './settings-disciplines-view.component.html',
  styleUrl: './settings-disciplines-view.component.scss'
})
export class SettingsDisciplinesViewComponent implements OnInit {
  loading = true;
  showRanks = true;
  discipline: DisciplineGrouped | undefined;
  touched = false;
  settingsAlertsForm: FormGroup;
  renaming = false;
  submitting = false;

constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private disciplineService: DisciplineService, private matDialog: MatDialog, private toasterService: ToasterService) {
    this.settingsAlertsForm = this.fb.group({
        notifyMember: [false],
        notifyGuest: [false],
        notifyGradedStudent: [false],
        notifyNonGradedStudent: [false],
        visitsRequired: [60],
        privateWeight: [1]
    })
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.disciplineService.getDisciplinGrades(+id).subscribe({
        next: discipline => {
          this.discipline = discipline;
          this.discipline.grades = this.discipline.grades.sort((a, b) => a.order - b.order);
          this.loading = false;
          if (this.discipline.alertSettings) {
            this.settingsAlertsForm.patchValue(this.discipline.alertSettings);
          }
        }
      })
    }
  }

  rename(update: boolean) {
    this.renaming = !update;

    if (update && this.discipline) {
      this.disciplineService.updateDisciplineName(this.discipline?.id, this.discipline?.name).subscribe({
        next: () => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully updated'});
        }
      });
    }
  }

  addRank() {
    const dialogRef = this.matDialog.open(AddDisciplineModalComponent, {
          data: { title: 'Add rank' },
          width: '400px'
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (!result || !result.name) {
            return;
          }
    
          this.discipline?.grades.push({
            gradeName: result.name,
            order: this.discipline.grades.length
          })
          this.touched = true;
      });
  }

  deleteDiscipline() {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: { title: 'Are you sure?', content: 'Are you sure you want to delete this discipline? This action cannot be undone.', noText: 'Cancel', yesText: 'Delete', yesColourRed: true },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && this.discipline) {
         
          this.disciplineService.deleteDiscipline(this.discipline.id).subscribe({
            next: () => { 
              this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully deleted discipline'});
              this.router.navigateByUrl('/settings/disciplines')
            }
          });
        }
      }
    })
  }

  deleteRank(i: number) {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: { title: 'Are you sure?', content: 'Are you sure you want to delete this rank? This action cannot be undone.', noText: 'Cancel', yesText: 'Delete', yesColourRed: true },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && this.discipline) {
          const grade = this.discipline.grades[i];
          
          if (grade.gradeId) {
            this.disciplineService.deleteRank(this.discipline.id, grade.gradeId).subscribe({
              next: () => { 
                this.removeRank(i)
                this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully deleted rank'});
              },
              error: (error) => {
                this.toasterService.addToaster({type: ToasterType.Error, message: error.error.message});
              }
            });
          } else {
            this.removeRank(i);
            this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully deleted rank'});
          }
        }
      }
    })
  }

  removeRank(i: number) {
    if (!this.discipline) {
      return;
    }

    for (let gradeIndex = i+1; gradeIndex < this.discipline.grades.length; gradeIndex++) {
      this.discipline.grades[gradeIndex].order -= 1;
    }

    this.discipline.grades.splice(i, 1);
    this.touched = true;
  }

  update(grade: DisciplineGroupedGrade) {
    const dialogRef = this.matDialog.open(AddDisciplineModalComponent, {
      data: { title: 'Update rank', name: grade.gradeName },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || !result.name) {
        return;
      }

      grade.gradeName = result.name;
      this.touched = true;
  });
  }

  up(i: number) {
    if (this.discipline) {
      const temp = this.discipline?.grades[i];
      this.discipline.grades[i] = this.discipline.grades[i-1];
      this.discipline.grades[i-1] = temp;
      this.discipline.grades[i].order += 1;
      this.discipline.grades[i-1].order -= 1;
      this.touched = true;
    }
  }

  down(i: number) {
    if (this.discipline) {
      const temp = this.discipline?.grades[i];
      this.discipline.grades[i] = this.discipline.grades[i+1];
      this.discipline.grades[i+1] = temp;
      this.discipline.grades[i].order -= 1;
      this.discipline.grades[i+1].order += 1;
      this.touched = true;
    }
  }

  save() {
    if (this.discipline) {
      this.submitting = true;
      if (this.showRanks) {
        this.disciplineService.updateDiscipline(this.discipline).subscribe({
          next: () => {
            this.loadData();
            this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully updated'});
            this.touched = false;
            this.submitting = false;
          }
        });
      } else {
        this.disciplineService.updateDisciplineAlertSettings(this.discipline.id, this.settingsAlertsForm.value).subscribe({
          next: () => {
            this.loadData();
            this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully updated'});
            this.touched = false;
            this.submitting = false;
            this.settingsAlertsForm.markAsPristine();
          }
        });
      }
    }
  }
  
}
