@if (loading) {
    <app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
}
@else if (disciplines.length > 0) {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        <fc-button-primary (click)="addDiscipline()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span>
                Add discipline
            </span>
        </fc-button-primary>
    </div>
    <app-disciplines-grid [disciplines]="disciplines"></app-disciplines-grid>
}
@else {
    <app-disciplines-empty (addDiscipline)="addDiscipline()"></app-disciplines-empty>
}
