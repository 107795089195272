<form [formGroup]="trainingCardForm" class="select-none w-full" (ngSubmit)="onSubmit()">
    <div class="w-full border border-gray-200 overflow-hidden rounded-lg">
        <div class="flex flex-row w-full h-[50px] px-6 bg-gray-50 justify-start items-center gap-[20px]">
            <div class="justify-center items-center gap-2 flex">
                <div class="text-gray-500 text-[18px] font-medium leading-7">
                    {{grade.disciplineName}}
                </div>
                <div class="py-1 px-2 bg-primary-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="w-2 h-2 relative bg-primary-700"></div>
                    <div class="text-center text-primary-700 text-[12px] font-medium leading-none">
                        {{gradeName}}
                    </div>
                </div>
            </div>
            <div class="justify-end items-center gap-2 flex grow">
                <button *ngIf="trainingCardForm.dirty"
                    class="text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2"
                    type="submit">
                    Save
                </button>
                <div class="cursor-pointer" (click)="toggleExpand()">
                    <chevron-up-outline-icon *ngIf="!expanded" class="h-5 w-5"></chevron-up-outline-icon>
                    <chevron-down-outline-icon *ngIf="expanded" class="h-5 w-5"></chevron-down-outline-icon>
                </div>
            </div>
        </div>
        <ng-container *ngIf="expanded">
        <div class="bg-white px-6 py-5 flex-col justify-start items-start gap-5 flex" [ngClass]="{'border-t': expanded}">
            <div class="w-full pb-5 border-b border-gray-200 justify-start items-start gap-8 flex flex-col sm:flex-row">
                <div class="text-gray-700 text-sm font-medium">
                    Rank progress
                </div>
                <div class="w-full sm:w-auto grow flex-col justify-start items-start inline-flex">
                    <div class="w-full flex-col justify-start items-end gap-2 flex">
                        <div class="w-full h-2 relative rounded-lg">
                            <div class="w-full h-2 left-0 top-0 absolute bg-gray-100 rounded"></div>
                            <div [style.width.%]="groupProgression" [class.rounded-r]="privateProgression == 0" class="h-2 left-0 top-0 absolute bg-orange-600 rounded-l"></div>
                            <div [style.width.%]="privateProgression" [class.rounded-l]="groupProgression == 0" [style.left.%]="groupProgression" class="h-2 top-0 absolute bg-blue-600 rounded-r"></div>
                        </div>
                        <div class="w-full justify-between inline-flex">
                            <div class="inline-flex">
                                <div class="px-2 py-[2px] bg-orange-50 rounded-2xl justify-center items-center flex">
                                    <div class="text-center text-orange-700 text-[12px] font-medium leading-none">
                                       <span class="hidden sm:flex">Group sessions</span>
                                       <span class="flex sm:hidden">Group</span>
                                    </div>
                                </div>  
                                <div class="px-2 py-[2px] bg-blue-50 rounded-2xl justify-center items-center flex">
                                    <div class="text-center text-blue-700 text-[12px] font-medium leading-none">
                                        <span class="hidden sm:flex">Private sessions</span>
                                        <span class="flex sm:hidden">Private</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-gray-700 text-sm font-medium">
                                {{totalProgression.toFixed(1)}}%
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full sm:w-auto px:0 sm:px-5 flex-col justify-start sm:items-end inline-flex">
                    <div class="flex items-center">
                        <mat-slide-toggle formControlName="manageGrade"
                        class="relative inline-flex items-center rounded-full transition-colors"
                        [color]="'primary'"
                        [disableRipple]="true">
                      </mat-slide-toggle>
                      <mat-label class="text-sm font-medium ml-4">Managing rank?</mat-label>
                    </div>
                </div>
            </div>
            <div class="w-full pb-5 border-b border-gray-200 justify-start items-start gap-4 sm:gap-8 flex flex-col sm:flex-row">
                <div class="w-full sm:w-1/4 text-gray-700 text-sm font-medium">
                    Current rank
                </div>
                <div class="w-full p-[0px] justify-start items-start gap-6 flex flex-col sm:flex-row">
                    <div class="w-full sm:w-[227px] p-[0px] flex-col justify-start items-start inline-flex">
                        <mat-select formControlName="gradeId" [hideSingleSelectionIndicator]="true"
                            class="bg-white border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 pr-4 rounded-lg w-full sm:w-[227px] hover:bg-gray-50">
                            <mat-select-trigger
                                class="flex justify-between text-left text-sm pl-4 py-3">
                                <span>
                                  {{gradeName}}
                                </span>
                              </mat-select-trigger>
                            <mat-option *ngFor="let grade of gradeOptions" [value]="grade.gradeId">
                                {{grade.gradeName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="w-full p-[0px] rounded-lg justify-start items-start flex">
                        <!-- Mobile date picker -->
                        <input formControlName="date" (click)="date.open()" [matDatepicker]="date" (dateChange)="dateChange($event)"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-36 hover:bg-gray-50"/>
                        <calendar-outline-icon class="heroicon-sw-1.5 absolute mt-3 right-[4.2rem] sm:right-auto sm:ml-28 w-6 text-gray-500"></calendar-outline-icon>
                        <mat-datepicker  touchUi #date></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="w-full pb-5 border-b border-gray-200 justify-start items-start gap-4 sm:gap-8 flex flex-col sm:flex-row">
                <div class="w-full sm:w-1/4 text-gray-700 text-sm font-medium">
                    Group sessions
                </div>
                <div class="w-full sm:w-auto p-[0px] justify-center sm:justify-start items-start gap-8 flex">
                    <div class="w-[100px] h-[70px] p-[0px] flex-col justify-start items-start inline-flex gap-4">
                        <div class="h-[20px] p-[0px] flex-col justify-start items-start flex">
                            <div class=" h-[20px] p-[0px] flex-col justify-start items-start gap-[6px] flex">
                                <div class=" h-[20px] p-[0px] flex-col justify-start items-center gap-[6px] flex">
                                    <div class="text-gray-700 text-sm font-medium">
                                        At current rank
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="w-[100px] h-[50px] text-center text-gray-700 text-[16px] font-medium leading-normal">
                            {{ grade.progress.groupCurrent }}
                        </div>
                    </div>
                    <div class="w-[100px] h-[70px] p-[0px] flex-col justify-start items-center inline-flex gap-4">
                        <div class=" h-[20px] p-[0px] flex-col justify-start items-center flex">
                            <div class=" h-[20px] p-[0px] flex-col justify-start items-center gap-[6px] flex">
                                <div class=" h-[20px] p-[0px] flex-col justify-start items-center gap-[6px] flex">
                                    <div class="text-gray-700 text-sm font-medium">
                                        Lifetime
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="w-[100px] h-[50px] text-center text-gray-700 text-[16px] font-medium leading-normal">
                            {{ grade.progress.groupTotal }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full justify-start items-start gap-4 sm:gap-8 flex flex-col sm:flex-row">
                <div class="w-full sm:w-1/4 text-gray-700 text-sm font-medium">
                    Private sessions
                </div>
                <div class="w-full sm:w-auto p-[0px] justify-center sm:justify-start items-start gap-8 flex">
                    <div class="w-[100px] h-[70px] p-[0px] flex-col justify-start items-start inline-flex gap-4">
                        <div class="h-[20px] p-[0px] flex-col justify-start items-start flex">
                            <div class=" h-[20px] p-[0px] flex-col justify-start items-start gap-[6px] flex">
                                <div class=" h-[20px] p-[0px] flex-col justify-start items-center gap-[6px] flex">
                                    <div class="text-gray-700 text-[14px] font-medium leading-tight">
                                        At current rank
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="w-[100px] h-[50px] text-center text-gray-700 text-[16px] font-medium leading-normal">
                            {{ grade.progress.privateCurrent }}
                        </div>
                    </div>
                    <div class="w-[100px] h-[70px] p-[0px] flex-col justify-start items-center inline-flex gap-4">
                        <div class=" h-[20px] p-[0px] flex-col justify-start items-center flex">
                            <div class=" h-[20px] p-[0px] flex-col justify-start items-center gap-[6px] flex">
                                <div class=" h-[20px] p-[0px] flex-col justify-start items-center gap-[6px] flex">
                                    <div class=" text-center text-gray-700 text-[14px] font-medium leading-tight">
                                        Lifetime
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="w-[100px] h-[50px] text-center text-gray-700 text-[16px] font-medium leading-normal">
                            {{ grade.progress.privateTotal }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
    </div>
</form>
