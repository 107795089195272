import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'fc-textarea',
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.css'
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() submitted: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string | undefined;
  @Input() placeholder: string = '';
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() helper: string | undefined;
  @Input() rows: number = 3;

  value: string = '';
  isDisabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  // Default error messages (used when no custom errorMessages are provided)
  defaultErrorMessages: { [key: string]: string } = {
    required: 'This field is required.',
    minlength: 'The input is too short.',
    maxlength: 'The input is too long.',
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  get isInvalid(): boolean {
    return this.submitted && (this.ngControl?.invalid ?? false);
  }

  get inputClasses(): string[] {
    const baseClasses = [
      'resize-none', 'w-full', 'border', 'focus:outline-0', 'focus:ring', 'px-4', 'py-3', 'rounded-lg', 'hover:bg-gray-50',
    ];

    if (this.isDisabled) {
      baseClasses.push('bg-gray-50', 'border-gray-300', 'text-gray-600');
    } else if (this.isInvalid) {
      baseClasses.push('border-error-300', 'focus:border-error-300', 'focus:ring-error-300');
    } else {
      baseClasses.push('border-gray-300', 'focus:border-primary-300', 'focus:ring-gray-100');
    }

    return baseClasses;
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value);
    this.onTouched();
  }

  getMergedErrorMessages(): { [key: string]: string } {
    return { ...this.defaultErrorMessages, ...this.errorMessages }; // Custom messages override defaults
  }

  getControlErrors(): { [key: string]: any } | null {
    return this.ngControl?.control?.errors || null;
  }

  getActiveErrorMessages(): string[] {
    const errors = this.getControlErrors();
    const mergedMessages = this.getMergedErrorMessages();

    if (!errors) {
      return [];
    }

    return Object.entries(errors).map(([errorType, errorValue]) =>
      typeof errorValue === 'string' ? errorValue : mergedMessages[errorType] || `Undefined error: ${errorType}`
    );
  }
}
