<fc-grid [columns]="columns" [data]="memberships" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary [routerLink]="[row.id]">
            View
        </fc-button-secondary>
    </div>
</ng-template>

<ng-template #statusTemplate let-row>
    <div *ngIf="row.status == 1" class="py-1 px-2 bg-gray-100 rounded-2xl justify-center items-center gap-[6px] flex">
        <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Pending</div>
    </div>
    <div *ngIf="row.status == 2" class="py-1 px-2 bg-success-100 rounded-2xl justify-center items-center gap-[6px] flex">
        <div class="text-center text-success-700 text-[12px] font-medium leading-none">Active</div>
    </div>
    <div *ngIf="row.status == 3" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
        <div class="text-center text-error-700 text-[12px] font-medium leading-none">Inactive</div>
    </div>
    <div *ngIf="row.status == 4 && row.cancellationDate" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px]">
        <div class="text-center text-error-700 text-[12px] font-medium leading-none">Cancelling: {{row.cancellationDate | date:'dd-MM-yyyy'}}</div>
    </div>
</ng-template>