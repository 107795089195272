@if (loading) {
    <app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
}
@else {
    <div class="mt-3">
        <div class="mb-3" *ngFor="let month of history">
            <!-- Month Header -->
            <div class="w-full h-6 flex items-center gap-2 mb-3">
                <div class="flex-grow h-px bg-gray-200"></div>
                <div class="text-center text-gray-500 text-base font-bold font-['Inter'] leading-normal">{{ month.key }}</div>
                <div class="flex-grow h-px bg-gray-200"></div>
            </div>
    
            <!-- Row -->
            <div class="space-y-8">
                <div class="flex items-start gap-4" *ngFor="let item of month.values; let last = last;">
                    <div class="relative flex flex-col items-center">
                        <!-- Icon -->
                        <div class="w-12 h-12 p-3 bg-white rounded-lg shadow border border-gray-200 flex justify-center items-center">
                            <div class="w-6 h-6" [ngSwitch]="item.type">
                                <document-check-outline-icon *ngSwitchCase="1"></document-check-outline-icon>
                                <user-circle-outline-icon *ngSwitchCase="2"></user-circle-outline-icon>
                                <trophy-outline-icon *ngSwitchCase="3"></trophy-outline-icon>
                                <currency-dollar-outline-icon *ngSwitchCase="4"></currency-dollar-outline-icon>
                                <stop-circle-outline-icon *ngSwitchCase="5"></stop-circle-outline-icon>
                            </div>
                        </div>
    
                        <!-- Line -->
                        <div *ngIf="!last" 
                            class="absolute bg-gray-300 w-[1px]" 
                            style="top: calc(100% + 0.25rem); height: 50%; left: 50%; transform: translateX(-50%);">
                        </div>
                    </div>
    
                    <!-- Row Details -->
                    <div class="flex-grow flex flex-col gap-1 mt-0.5">
                        <div class="text-slate-700 text-sm font-semibold">{{ item.title }}</div>
                        <div class="text-slate-600 text-sm">
                            {{ item.message }}
                            <a class="text-primary-600" *ngIf="item.url" [routerLink]="item.url">View details.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}