import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Discipline, DisciplineGrouped } from '../models/discipline.model';

@Injectable({
  providedIn: 'root'
})
export class DisciplineService {

  constructor(private http: HttpClient) { }

  createDiscipline(name: string) {
    return this.http.post<number>(`${environment.baseUrl}/discipline`, { name: name });
  }

  updateDiscipline(discipline: DisciplineGrouped) {
    return this.http.post(`${environment.baseUrl}/discipline/${discipline.id}`, discipline);
  }

  updateDisciplineName(id: number, name: string) {
    return this.http.post(`${environment.baseUrl}/discipline/${id}/name`, { name: name });
  }

  updateDisciplineAlertSettings(disciplineId: number, alertSettings: any) {
    return this.http.post(`${environment.baseUrl}/discipline/${disciplineId}/alert`, alertSettings);
  }

  getDisciplines() {
    return this.http.get<Discipline[]>(`${environment.baseUrl}/discipline`);
  }

  getDisciplineGrades() {
    return this.http.get<DisciplineGrouped[]>(`${environment.baseUrl}/discipline/grades`);
  }

  getDisciplinGrades(id: number) {
    return this.http.get<DisciplineGrouped>(`${environment.baseUrl}/discipline/${id}/grades`);
  }

  deleteDiscipline(disciplineId: number) {
    return this.http.delete(`${environment.baseUrl}/discipline/${disciplineId}`);
  }

  deleteRank(disciplineId: number, gradeId: number) {
    return this.http.delete(`${environment.baseUrl}/discipline/${disciplineId}/grade/${gradeId}`);
  }
}
