import { Component, OnInit} from '@angular/core';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { MatDialog } from '@angular/material/dialog';
import { MembershipService } from 'src/app/services/membership.service';
import { Membership } from 'src/app/models/membership.model';
import { StripeService } from 'src/app/services/stripe.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Discipline } from 'src/app/models/discipline.model';

@Component({
  selector: 'app-settings-memberships',
  templateUrl: './settings-memberships.component.html',
  styleUrls: ['./settings-memberships.component.scss']
})
export class SettingsMembershipsComponent implements OnInit {
  activeMemberships: Membership[] = [];
  archivedMemberships: Membership[] = [];
  billingCompleted: boolean = false;
  loadingStripe: boolean = false;
  loading: boolean = true;

  toggleOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];
  toggleValue = this.toggleOptions[0].value;

  constructor(private membershipService: MembershipService, private stripeService: StripeService, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    forkJoin([
      this.membershipService.getMemberships(),
      this.membershipService.billingCompleted()
    ]).subscribe((results) => {
      const memberships = results[0];
      this.activeMemberships = memberships.filter((d) => !d.isArchived);
      this.archivedMemberships = memberships.filter((d) => d.isArchived);

      if (this.activeMemberships.length == 0 && this.archivedMemberships.length > 0) {
        this.toggleValue = this.toggleOptions[1].value;
      }

      this.billingCompleted = results[1];

      this.loading = false;
    })
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }

  setupStripe() {
    this.loadingStripe = true;
    this.stripeService.createAccount().subscribe(result => {
      window.open(result, '_blank');
      this.loadingStripe = false;
    });
  }

  getDisciplines(disciplines: Discipline[]) {
    return disciplines.map(x => x.name).join(', ');
  }

  addMembership(): void {
    this.router.navigate(['create'], {relativeTo: this.route});
  }
}
