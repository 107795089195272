<form [formGroup]="membershipCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-8 mb-8 border-b border-gray-200 pb-10">
        <div class="text-xl text-gray-900 mb-2">
            Overview
        </div>
        <div class="flex flex-col gap-5 items-start">
            <fc-text-input class="w-full md:w-1/2"
                formControlName="name"
                label="Name"
                placeholder="Required..."
                [submitted]="submitted"
                [required]="true"
                [errorMessages]="{required:'Membership name is required'}"
            />
            @if (documents.length > 0) {
            <div class="w-full md:w-1/2">
                <fc-select-input formControlName="termsAndConditionsDocumentId" [options]="documents" label="Terms and conditions document"></fc-select-input>
            </div>
            }
            <div class="w-full md:w-1/2">
                <label class="block mb-2 text-sm font-medium text-gray-700">Description</label>
                <textarea type="text" rows="3" formControlName="description"
                    [ngClass]="{'resize-none w-full border focus:outline-0 focus:ring px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !membershipCreateForm.get('description')?.valid, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || membershipCreateForm.get('description')?.valid ?? true}"
                    placeholder="Enter a description..."></textarea>
            </div>
        </div>
    </div>
    <div class="mt-8 mb-8 border-b border-gray-200 pb-10">
        <div class="text-xl text-gray-900 mb-2">
            Benefits
        </div>
        <div class="w-full md:w-1/2">
            <label class="block mb-2 text-sm font-medium text-gray-700">Discipline</label>
            <mat-select  multiple formControlName="disciplineIds" 
            [ngClass]="{'bg-gray-50 text-gray-600' : editing, 'w-full border focus:outline-0 focus:ring px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !membershipCreateForm.valid && !membershipCreateForm.get('disciplineIds')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || membershipCreateForm.valid || membershipCreateForm.get('disciplineIds')?.valid ?? true}">
                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id" class="-ml-2">{{discipline.name}}</mat-option>
            </mat-select>
            <span *ngIf="!editing && submitted && !membershipCreateForm.get('disciplineIds')?.valid ?? false"  class="block mt-2 text-error-500">At least one discipline is required</span>
        </div>
    </div>
    <div class="mt-8 mb-8">
        <div class="text-xl text-gray-900 mb-2">
            Payments
        </div>
        <div class="flex flex-col gap-4 my-6">
            <div class="flex flex-col sm:flex-row gap-2 pb-5">
                <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">Frequency:</span>
                <mat-select [hideSingleSelectionIndicator]="true" formControlName="paymentFrequency" [class.bg-gray-100]="membershipCreateForm.get('paymentFrequency')?.disabled ?? false"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-100 basis-1 sm:basis-[202px]">
                    <mat-select-trigger>
                    <span>
                        {{paymentFrequency}}
                    </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let paymentFrequency of paymentFrequencies" [value]="paymentFrequency.id" class="-ml-2">{{paymentFrequency.name}}</mat-option>
                </mat-select>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5">
                <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">Amount:</span>
                <div class="flex flex-col gap-4">
                    <div class="flex basis-1 ml-2 w-[202px] h-[44px]">
                        <div class="text-gray-700 -mr-6 basis-[16px] mt-3 z-10">$</div>
                        <input type="number" formControlName="paymentAmount" (ngModelChange)="paymentAmountChange()" (blur)="formatDecimal('paymentAmount')"
                        [ngClass]="{'disabled:bg-gray-100 border-gray-300 focus:border-primary-300 focus:ring-primary-100': !submitted || membershipCreateForm.get('paymentAmount')?.valid ?? true, 'border-error-300 focus:border-error-300 focus:ring-error-100': submitted && !membershipCreateForm.get('paymentAmount')?.valid, 'border focus:outline-0 focus:ring  text-sm pl-8 pr-4 py-3 rounded-lg w-full': true}" />
                    </div>
                    <div *ngIf="submitted && !membershipCreateForm.valid && !membershipCreateForm.get('paymentAmount')?.valid ?? false" >
                        <span class="block mt-2 text-error-500">Payment amount must be greater than $0</span>
                    </div>
                    <div class="flex gap-2">
                        <input type="checkbox" id="paymentTaxInclusive" formControlName="paymentTaxInclusive" (change)="paymentAmountChange()"
                            class="mt-[2px] w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                        <div class="flex flex-col basis-[360px]">
                            <label for="paymentTaxInclusive" class="text-gray-700 font-medium text-sm">
                                Tax inclusive
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2">
                <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">FightCloud fees:</span>
                <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[140px]">Direct debit</span>
                <span class="font-medium text-gray-700 text-sm basis-1 ml-2 sm:basis-[140px]">Card</span>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-1">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                </span>
                <div class="flex flex-col gap-4">
                    <div class="flex gap-2">
                        <div class="flex basis-1 sm:basis-[140px] ml-2">
                            <div class="text-gray-700 -mr-6 basis-[16px] mt-3 z-10">$</div>
                            <input readonly type="number" formControlName="serviceAmountDebit" (blur)="formatDecimal('serviceAmountDebit')"
                                class="border border-gray-300 bg-gray-100 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pl-8 pr-4 py-3 rounded-lg w-full" />
                        </div>
                        <div class="flex basis-1 sm:basis-[140px] ml-2">
                            <div class="text-gray-700 -mr-6 basis-[16px] mt-3 z-10">$</div>
                            <input readonly type="number" formControlName="serviceAmountCard" (blur)="formatDecimal('serviceAmountCard')"
                                class="border border-gray-300 bg-gray-100 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pl-8 pr-4 py-3 rounded-lg w-full" />
                        </div>
                    </div>
                    <div class="flex gap-2">
                        <input type="checkbox" id="chargeFees" formControlName="chargeFees" (change)="paymentAmountChange()" 
                        class="mt-[2px] w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600"  />
                        <div class="flex flex-col basis-[360px]">
                            <label for="chargeFees" class="text-gray-700 font-medium text-sm">
                                Add FightCloud processing fees to amount
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-center xl:justify-start gap-10 mb-12">
        <div class="w-80">
            <app-payment-example-card
            heading="Example Direct Debit Payment" 
            [amount]="paymentAmount" 
            [service]="serviceAmountDebit" 
            [chargeFees]="chargeFees"></app-payment-example-card>
        </div>
        <div class="w-80">
            <app-payment-example-card
            heading="Example Card Payment"
            [amount]="paymentAmount" 
            [service]="serviceAmountCard" 
            [chargeFees]="chargeFees"></app-payment-example-card>
        </div>
    </div>
    <app-save-footer *ngIf="this.membershipCreateForm.dirty" [loading]="loading" [buttonText]="editing ? 'Update membership' : 'Create membership'" [error]="submitted && !membershipCreateForm.valid"></app-save-footer>
</form>
