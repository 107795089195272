@if (loading) {
    <app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
}
@else if (activeDocs.length == 0 && archivedDocs.length == 0) {
    <fc-empty-grid
        heading="Create documents to share with students!">
        <p description>Click new document to get started.</p>
        <fc-button-primary variant="large" (click)="addDocument()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span>New document</span>
        </fc-button-primary>
    </fc-empty-grid>
}
@else {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        <fc-button-primary (click)="addDocument()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">New document</span>
            <span class="flex sm:hidden">New</span>
        </fc-button-primary>
    </div>
    @if (activeDocs.length !== 0 && archivedDocs.length !== 0) {
        <div class="flex justify-between items-center mt-6">
            <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue"/>
        </div>
    }
    @if (toggleValue == 'active' && activeDocs.length > 0) {
        <app-documents-grid [documents]="activeDocs"/>
    } @else if (toggleValue == 'archived' && archivedDocs.length > 0) {
        <app-documents-grid [documents]="archivedDocs"/>
    }
}