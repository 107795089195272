import { Component, Input, TemplateRef } from '@angular/core';
import { GridColumn } from './grid-column.model';

@Component({
  selector: 'fc-grid',
  templateUrl: './grid.component.html',
})

export class GridComponent<T> {
  @Input() columns: GridColumn<T>[] = [];
  @Input() data: T[] = [];
  @Input() actionTemplate?: TemplateRef<any>;
}