import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { Discipline } from 'src/app/models/discipline.model';
import { Membership } from 'src/app/models/membership.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { MembershipService } from 'src/app/services/membership.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-settings-membership-create',
  templateUrl: './settings-membership-create.component.html',
  styleUrls: ['./settings-membership-create.component.scss']
})
export class SettingsMembershipCreateComponent implements OnInit {
  loading: boolean = true;
  editing: boolean = false;
  membership: Membership | undefined = undefined;
  disciplines: Discipline[] = [];
  documentId: number | null = null;

  constructor(private route: ActivatedRoute, private membershipService: MembershipService, private disciplineService: DisciplineService, private dialog: MatDialog, private toasterService: ToasterService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.documentId = +id;
      this.editing = true;
      forkJoin({
        membership: this.membershipService.getMembership(+id),
        disciplines: this.disciplineService.getDisciplines()
      }).subscribe(result => {
        this.membership = result.membership;
        this.disciplines = result.disciplines;
        this.loading = false;
      });
    }
    else {
      this.disciplineService.getDisciplines().subscribe(result => {
        this.disciplines = result.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        this.loading = false;
      })
    }
  }

  archiveMembership() {
    if (this.membership) {
      const membership = this.membership

      if (membership.students.length > 0) {
        this.dialog.open(ConfirmModalComponent, {
          width: '400px',
          data: {
            title: "Cannot archive membership",
            content: `This membership cannot be archived because there are ${membership.students.length} active memberships attached to it. Please cancel all subscriptions before archiving. ${this.generateStudentsList(membership.students)}`,
            yesText: "Ok"
          }
        });

        return;
      }

      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Archive membership",
          content: "Are you sure you want to archive this membership?",
          yesText: "Yes, archive it",
          noText: "Cancel",
          yesColourRed: true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.membershipService.archiveMembership(membership.id).subscribe({
            next: () => {
              if (this.membership) {
                this.membership.isArchived = true;
              }
              this.toasterService.addToaster({ type: ToasterType.Success, message: 'Membership archived' });
            },
            error: () => {
              this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed archive membership' });
            }
          });
        }
      });
    }
  }
  
  restoreMembership() {
    if (this.membership) {
      const membership = this.membership;
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Restore membership",
          content: "Are you sure you want to restore this memberhsip? Restoring this membership will not restore any memberships attached to students.",
          yesText: "Restore",
          noText: "Cancel",
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.membershipService.restoreMembership(membership.id).subscribe({
            next: () => {
              if (this.membership) {
                this.membership.isArchived = false;
              }
              this.toasterService.addToaster({ type: ToasterType.Success, message: 'Membership restored' });
            },
            error: () => {
              this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed restore membership' });
            }
          });
        }
      });
    }
  }

  private generateStudentsList(students: string[]) {
    let result = '<ul class="list-disc px-6 mt-2">';
    students.forEach(student => {
      result += `<li>${student}</li>`
    });
    result += '</ul>';

    return result;
  }
}
