import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fc-toggle-button-group',
  templateUrl: './toggle-button-group.component.html',
})
export class ToggleButtonGroupComponent {
  @Input() options: { label: string; value: any }[] = [];
  @Input() selectedValue: any;
  @Output() selectedValueChange = new EventEmitter<any>();

  selectOption(value: any, event: Event): void {
    this.selectedValue = value;
    this.selectedValueChange.emit(this.selectedValue);

    const target = event.target as HTMLElement;
    target.blur();
  }
}
