import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { StudentDocument } from 'src/app/models/student-document.model';

@Component({
  selector: 'app-student-documents-grid',
  templateUrl: './student-documents-grid.component.html',
  styleUrl: './student-documents-grid.component.scss'
})
export class StudentDocumentsGridComponent implements AfterViewInit {
  @Input() documents: StudentDocument[] = [];
  @Output() exportDocument = new EventEmitter<StudentDocument>();

  columns: GridColumn<StudentDocument>[] = [];

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Name',
        field: 'name',
        classes: 'w-1/2'
      },
      {
        header: 'Version',
        formatter: (row) => row.version.toString(),
        classes: 'text-center'
      },
      {
        header: 'Status',
        formatter: (row) =>
          row.signatureDate ? `Signed on ${this.localDate(row.signatureDate)}` : 'Not yet signed',
        classes: 'w-1/5'
      }
    ];
    this.ref.detectChanges();
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }

  export(row: StudentDocument) {
    this.exportDocument.emit(row);
  }
}