<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">
      {{ label }}
      <span class="text-primary-700" *ngIf="required">*</span>
    </label>
    <div class="flex items-center gap-4">
      <input
        [ngxTimepicker]="timePicker"
        [value]="value"
        [ngClass]="inputClasses"
        [placeholder]="placeholder"
        [disabled]="isDisabled"
      />
      <ng-content></ng-content>
    </div>
    <ngx-material-timepicker
      #timePicker
      [theme]="timePickerTheme"
      [defaultTime]="defaultTime"
      (timeSet)="onTimeSet($event)">
    </ngx-material-timepicker>
    <span *ngIf="helper" class="text-sm text-gray-600 mt-2">{{ helper }}</span>
    <ng-container *ngIf="submitted">
        <span *ngFor="let errorMessage of getActiveErrorMessages()" class="block mt-2 text-error-500">{{ errorMessage }}</span>
    </ng-container>
  </div>
  