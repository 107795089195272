<form [formGroup]="studentCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-4 mb-20">
        <div class="mb-2 font-semibold text-lg text-gray-900">Student details</div>
        <div class="flex flex-col sm:flex-row pb-5">
            <div class="mr:0 sm:mr-[130px]">
                <div class="flex-1">
                    <label class="block mb-2 text-sm font-medium text-gray-700">First Name</label>
                    <input type="text" formControlName="firstName"
                        [ngClass]="{'w-full sm:w-[350px] border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('firstName')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('firstName')?.valid ?? true}"
                        placeholder="Required..." />
                    <span *ngIf="submitted && !studentCreateForm.get('firstName')?.valid ?? false"  class="block mt-2 text-error-500">First name is required</span>
                </div>
                <div class="mt-5">
                    <div class="flex-1">
                        <label class="block mb-2 text-sm font-medium text-gray-700">Last Name</label>
                        <input type="text" formControlName="lastName"
                            [ngClass]="{'w-full sm:w-[350px] border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('lastName')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('lastName')?.valid ?? true}"
                            placeholder="Required..." />
                        <span *ngIf="submitted && !studentCreateForm.get('lastName')?.valid ?? false" class="block mt-2 text-error-500">Last name is required</span>
                    </div>
                </div>
            </div>
            <div class="order-first sm:order-last w-full flex justify-center sm:justify-start">
                <div (click)="uploadImage()"
                    class="cursor-pointer inline-flex items-center justify-center w-[160px] h-[160px] overflow-hidden bg-primary-50 hover:bg-primary-200 rounded-full">
                    <img *ngIf="imageUrl" [src]="imageUrl" />
                    <user-outline-icon *ngIf="!imageUrl" class="p-10" svgClass="stroke-1 stroke-primary-600" [size]="80"></user-outline-icon>
                </div>
            </div>
        </div>
        <div class="pb-5 flex flex-col sm:flex-row gap-4">
            <div class="flex flex-col gap-1">
                <label class="text-sm font-medium text-gray-700">Date of birth</label>
                <input [value]="studentCreateForm.get('dateOfBirth')?.value" (click)="date.open()" [matDatepicker]="date" (dateChange)="dateChange($event)"
                    [ngClass]="{'w-full sm:w-[350px] border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('dateOfBirth')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('dateOfBirth')?.valid ?? true}"
                    />
                    <mat-datepicker  touchUi #date></mat-datepicker>
                <span *ngIf="submitted && !studentCreateForm.get('dateOfBirth')?.valid ?? false"  class="block mt-2 text-error-500">Date of birth is required</span>
            </div>
        </div>
        <div class="pb-10 flex flex-col sm:flex-row gap-4">
            <div class="sm:max-w-[350px] grow">
                <fc-text-input label="Email" placeholder="" formControlName="email" [submitted]="submitted" [errorMessages]="{'required':'Please enter a valid email'}">
                    <envelope-outline-icon #icon></envelope-outline-icon>
                </fc-text-input>
            </div>
            <div class="mt-5 sm:mt-0 sm:max-w-[350px] grow">
                <div class="flex-1">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Mobile</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <device-phone-mobile-outline-icon
                                svgClass="w-5 h-5 text-gray-500 dark:text-gray-400"></device-phone-mobile-outline-icon>
                        </div>
                        <input type="text" formControlName="mobile"
                            [ngClass]="{'w-full border focus:outline-0 focus:ring text-sm px-10 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('mobile')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('mobile')?.valid ?? true}"
                            />
                    </div>
                    <span *ngIf="submitted && !studentCreateForm.get('mobile')?.valid ?? false"  class="block mt-2 text-error-500">Please enter a valid mobile</span>
                </div>
            </div>
        </div>
        <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900">Emergency contact information</div>
        <div formGroupName="emergencyContact" class="mt-5 pb-10">
            <div class="flex flex-col sm:flex-row justify-start gap-5">
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact First Name</label>
                    <input type="text" formControlName="emergencyFirstName"
                        class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg hover:bg-gray-50" />
                </div>
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact Last Name</label>
                    <input type="text" formControlName="emergencyLastName"
                        class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg hover:bg-gray-50" />
                </div>
            </div>
            <div class="mt-5 flex flex-col sm:flex-row justify-start gap-5">
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact Relationship</label>
                    <input type="text" formControlName="emergencyRelationship"
                        class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg hover:bg-gray-50" />
                </div>
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact Mobile</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <device-phone-mobile-outline-icon
                                svgClass="w-5 h-5 text-gray-500 dark:text-gray-400"></device-phone-mobile-outline-icon>
                        </div>
                        <input type="text" formControlName="emergencyMobile"
                            [ngClass]="{'w-full border focus:outline-0 focus:ring text-sm px-10 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('emergencyContact')?.get('emergencyMobile')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('emergencyContact')?.get('emergencyMobile')?.valid ?? true}"
                            />
                    </div>
                    <span *ngIf="submitted && !studentCreateForm.get('emergencyContact')?.get('emergencyMobile')?.valid ?? false"  class="block mt-2 text-error-500">Please enter a valid mobile</span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!student">
        <div>
            <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900">Training history</div>
            <div class="text-gray-600 text-sm mb-2">Add previous discipline experience and choose if you’ll be managing the students rank</div>
        </div>
        <div class="mb-10">
            <button *ngIf="disciplinesAvailable.length > 0" (click)="addTrainingHistory()" type="button" class="flex rounded-lg border px-3.5 py-2 border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0">
                <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
                Add training
            </button>
        </div>
        <div *ngIf="selectedDisciplines.length > 0" class="my-4 border overflow-y-hidden rounded-lg">
            <table class="w-full overflow-scroll">
                <thead class="bg-gray-50 text-gray-500">
                    <tr class="text-left">
                        <th class="pl-6 py-3 w-1/4 font-medium">
                            Discipline
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Rank
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Date
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Managing their rank?
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="[&>*:not(:first-child)]:border-t">
                    <tr class="hover:bg-gray-50" *ngFor="let discipline of selectedDisciplines">
                        <td class="px-6 py-4 cursor-pointer">
                            <span class="font-medium">{{discipline.disciplineName}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{discipline.gradeName}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{discipline.gradeDate | date}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{discipline.manageRank ? 'Yes' : 'No'}}</span>
                        </td>
                        <td>
                            <div class="flex justify-end">
                                <trash-outline-icon (click)="removeTrainingHistory(discipline.disciplineId)" svgClass="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900 border-t">Membership</div>
            <div class="text-gray-600 text-sm mb-2">Membership invitations will be sent after student record is created</div>
        </div>
        <div class="mb-2 flex gap-2">
            <button [disabled]="!hasEmail" [matTooltip]="hasEmail ? '' : 'Student must have a valid email to send a membership invitation'" *ngIf="selectedMembershipIds.length === 0" (click)="addMembership()" type="button" class="flex rounded-lg border px-3.5 py-2 border-gray-300 disabled:bg-gray-200 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0">
                <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
                Add membership plan
            </button>
            <button (click)="copyText()" type="button" [class.hover:bg-primary-600]="copyingText" [class.bg-primary-600]="copyingText" [class.text-white]="copyingText" class="flex rounded-lg border px-3.5 py-2 border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0">
                <span *ngIf="!copyingText">Student portal</span>
                <span *ngIf="copyingText">URL copied</span>
                <link-outline-icon *ngIf="!copyingText"  svgClass="ml-2 heroicon-sw-1.5" [size]="20"></link-outline-icon>
            </button>
        </div>
        <div *ngIf="selectedMembershipIds.length > 0" class="my-4 border overflow-y-hidden rounded-lg">
            <table class="w-full overflow-scroll">
                <thead class="bg-gray-50 text-gray-500">
                    <tr class="text-left">
                        <th class="pl-6 py-3 w-1/4 font-medium">
                            Membership
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Recurrence
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Cost (Credit Card)
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Cost (Direct Debit)
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="[&>*:not(:first-child)]:border-t">
                    <tr class="hover:bg-gray-50" *ngFor="let membership of selectedMemberships">
                        <td class="px-6 py-4 cursor-pointer">
                            <span class="font-medium">{{membership.name}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{getPaymentFrequency(membership.versions[0].paymentFrequency)}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{getTotalCharged(membership.versions[0].paymentAmount, membership.versions[0].serviceAmountCard, membership.versions[0].chargeFees) | currency}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{getTotalCharged(membership.versions[0].paymentAmount, membership.versions[0].serviceAmountDebit, membership.versions[0].chargeFees) | currency}}</span>
                        </td>
                        <td>
                            <div class="flex justify-end">
                                <trash-outline-icon (click)="removeMembership(membership.id)" svgClass="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </ng-container>
        <app-save-footer *ngIf="this.studentCreateForm.dirty" [buttonText]="editing ? 'Update student' : 'Create student'" [error]="submitted && !studentCreateForm.valid" [loading]="submitting"></app-save-footer>
    </div>
</form>