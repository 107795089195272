import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './form/input/text-input/text-input.component';
import { StepperComponent } from './stepper/stepper.component';
import { CommonModule } from '@angular/common';
import { ButtonPrimaryComponent } from './button/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './button/button-secondary/button-secondary.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { MatIconModule } from '@angular/material/icon';
import { RichTextEditorComponent } from './form/input/rich-text-editor/rich-text-editor.component';
import { SignaturePadComponent } from './form/input/signature-pad/signature-pad.component';
import { ErrorModalComponent } from './modal/error-modal/error-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EmptyGridComponent } from './grid/empty-grid/empty-grid.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SelectInputComponent } from './form/input/select-input/select-input.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TimeInputComponent } from './form/input/time-input/time-input.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { GridComponent } from './grid/grid/grid.component';
import { ToggleButtonGroupComponent } from './button/toggle-button-group/toggle-button-group.component';
import { TextareaComponent } from './form/input/textarea/textarea.component';



@NgModule({
  declarations: [
    TextInputComponent,
    StepperComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    RichTextEditorComponent,
    SignaturePadComponent,
    ErrorModalComponent,
    EmptyGridComponent,
    SelectInputComponent,
    TimeInputComponent,
    GridComponent,
    ToggleButtonGroupComponent,
    TextareaComponent,
  ],
  imports: [
    CommonModule,
    NgHeroiconsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatOptionModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinner,
  ],
  exports: [
    TextInputComponent,
    StepperComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    RichTextEditorComponent,
    SignaturePadComponent,
    ErrorModalComponent,
    EmptyGridComponent,
    SelectInputComponent,
    TimeInputComponent,
    GridComponent,
    ToggleButtonGroupComponent,
    TextareaComponent,
  ],
  providers: [
      MatDatepickerModule
  ]
})
export class CommonLibModule { }
