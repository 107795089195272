<form [formGroup]="documentCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-8 mb-20 flex flex-col gap-5 items-start">
        <fc-text-input class="w-1/2" formControlName="name" [submitted]="submitted" label="Document name" placeholder="Required..." [errorMessages]="{'required': 'Document name is required' }"/>
        <fc-textarea class="w-1/2" formControlName="description" label="Describe your document" placeholder="Enter a description..." [submitted]="submitted"/>
        <div class="w-1/2">
         <fc-select-input  formControlName="documentType" label="Document type" [options]="documentTypes" [submitted]="submitted"></fc-select-input>
        </div>
        @if (!isMembership) {
        <div class="w-1/2">
            <div class="flex gap-2 items-center">
                <mat-slide-toggle formControlName="signatureRequired"
                    class="relative inline-flex items-center rounded-full transition-colors"
                    [color]="'primary'"
                    [disableRipple]="true">
                </mat-slide-toggle>
                <mat-label class="text-sm font-medium text-gray-700">Require student signature?</mat-label>
            </div>
        </div>
        }
        <div class="w-full">
            <fc-rich-text-editor class="w-full" formControlName="content" (initialValueSet)="onInitialValueSet()" 
                label="Provide your document details" placeholder="Enter document details..."/>
            <span *ngIf="submitted && !documentCreateForm.get('content')?.valid"  class="block mt-2 text-error-500">Document details is required</span>
        </div>
    </div>
    <app-save-footer *ngIf="this.documentCreateForm.dirty" [buttonText]="editing ? 'Update document' : 'Create document'" [error]="submitted && !documentCreateForm.valid"></app-save-footer>
</form>

