<form [formGroup]="settingsAppsForm" (ngSubmit)="onSubmit()" class="mb-16">
    <!-- General -->
    <div class="flex flex-col gap-4 m-6">
        <div class="flex flex-col gap-2 pb-5 border-b">
            <div class="font-semibold text-gray-900 text-lg">Student registration kiosk</div>
        </div>
        <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
            <div class="flex flex-col basis-1 sm:basis-[280px]">
                <div class="font-semibold text-gray-700 text-sm">
                    Document
                </div>
                <div class="text-gray-600 text-sm">
                    Signed during registration process
                </div>
            </div>
            <div class="grow">
                <fc-select-input formControlName="waiverDocumentId" [options]="documents"/>
            </div>
        </div>
        <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
            <div class="flex flex-col basis-1 sm:basis-[280px]">
                <div class="font-semibold text-gray-700 text-sm">
                    Images
                </div>
                <div class="text-gray-600 text-sm">
                    PNG or JPG (max. 4MB)
                </div>
            </div>
            <div class="grow flex flex-col gap-4">
                <div class="border rounded-lg flex justify-between p-4">
                    <div class="text-sm text-gray-600">Get started page</div>
                    <img *ngIf="getStartedImageUrl" [src]="getStartedImageUrl" class="h-[100px]" (click)="uploadImage('getStartedImageUrl')">
                    <div *ngIf="!getStartedImageUrl" class="w-[175px] h-[100px] border rounded-lg bg-gray-50 border-gray-200" (click)="uploadImage('getStartedImageUrl')">
                        <div class="flex flex-col items-center mt-4 gap-2">
                            <button type="button" class="w-10 h-10 flex justify-center items-center bg-white text-gray-700 border border-gray-400 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-semibold rounded-lg text-center">
                                <cloud-arrow-up-outline-icon [size]="30"/>
                            </button>
                            <div class="font-semibold text-sm text-gray-400">Click to upload</div>
                        </div>
                    </div>
                </div>
                <div class="border rounded-lg flex justify-between p-4">
                    <div class="text-sm text-gray-600">Student details page</div>
                    <img *ngIf="studentDetailsImageUrl" [src]="studentDetailsImageUrl" class="h-[100px]" (click)="uploadImage('studentDetailsImageUrl')"/>
                    <div *ngIf="!studentDetailsImageUrl" class="w-[175px] h-[100px] border rounded-lg bg-gray-50 border-gray-200" (click)="uploadImage('studentDetailsImageUrl')">
                        <div class="flex flex-col items-center mt-4 gap-2">
                            <button type="button" class="w-10 h-10 flex justify-center items-center bg-white text-gray-700 border border-gray-400 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-semibold rounded-lg text-center">
                                <cloud-arrow-up-outline-icon [size]="30"/>
                            </button>
                            <div class="font-semibold text-sm text-gray-400">Click to upload</div>
                        </div>
                    </div>
                </div>
                <div class="border rounded-lg flex justify-between p-4">
                    <div class="text-sm text-gray-600">Emergency contact page</div>
                    <img *ngIf="emergencyContactImageUrl" [src]="emergencyContactImageUrl" class="h-[100px]" (click)="uploadImage('emergencyContactImageUrl')"/>
                    <div *ngIf="!emergencyContactImageUrl" class="w-[175px] h-[100px] border rounded-lg bg-gray-50 border-gray-200" (click)="uploadImage('emergencyContactImageUrl')">
                        <div class="flex flex-col items-center mt-4 gap-2">
                            <button type="button" class="w-10 h-10 flex justify-center items-center bg-white text-gray-700 border border-gray-400 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-semibold rounded-lg text-center">
                                <cloud-arrow-up-outline-icon [size]="30"/>
                            </button>
                            <div class="font-semibold text-sm text-gray-400">Click to upload</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="settingsAppsForm.dirty">
        <app-save-footer buttonText="Save" [error]="submitted && !settingsAppsForm.valid" [loading]="loading"></app-save-footer>
    </ng-container>
</form>
