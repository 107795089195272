import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Document } from 'src/app/models/document.model';
import { DocumentService } from 'src/app/services/document.service';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-settings-documents',
  templateUrl: './settings-documents.component.html',
  styleUrls: ['./settings-documents.component.scss']
})
export class SettingsDocumentsComponent implements OnInit {
  activeDocs: Document[] = [];
  archivedDocs: Document[] = [];
  loading: boolean = true;
  @ViewChild("hiddenPdfDiv") hiddenPdfDiv!: ElementRef<HTMLCanvasElement>;

  toggleOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];
  toggleValue = this.toggleOptions[0].value;

  constructor(private documentService: DocumentService, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.documentService.getDocuments().subscribe(results => {
      this.activeDocs = results.filter((d) => !d.isArchived);
      this.archivedDocs = results.filter((d) => d.isArchived);
      if (this.activeDocs.length == 0 && this.archivedDocs.length > 0) {
        this.toggleValue = this.toggleOptions[1].value;
      }
      this.loading = false;
    })
  }

  addDocument(): void {
    this.router.navigate(['create'], {relativeTo: this.route});
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }
}
