import { ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DisciplineGrouped } from 'src/app/models/discipline.model';
import { GridColumn } from '@fc-lib';

@Component({
  selector: 'app-disciplines-grid',
  templateUrl: './disciplines-grid.component.html',
  styleUrl: './disciplines-grid.component.scss'
})
export class DisciplinesGridComponent {
  @Input() disciplines: DisciplineGrouped[] = [];

  @ViewChild('nameTemplate', { static: true }) nameTemplate!: TemplateRef<any>;

  columns: GridColumn<DisciplineGrouped>[] = [];

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Discipline',
        template: this.nameTemplate, // template used here for an example
      },
      {
        header: 'Ranks',
        formatter: (row: DisciplineGrouped) => {
          return row.grades.length == 1 ? `${row.grades.length} rank` : `${row.grades.length} ranks`
        },
      },
      {
        header: 'Alerts',
        formatter: (row: DisciplineGrouped) => {
          return (row.alertSettings?.notifyGradedStudent ||
            row.alertSettings?.notifyGuest || 
            row.alertSettings?.notifyMember || 
            row.alertSettings?.notifyNonGradedStudent) ? 'Yes' : 'No'
        },
      },
    ];
    this.ref.detectChanges();
  }

}
