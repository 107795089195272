<div>
    <app-back-button></app-back-button>
</div>


@if (!loading && discipline) {
    <div class="mt-2 flex justify-between border-b pb-4">
        <div class="flex gap-1">
            @if (!renaming) {
            <h1 class="text-3xl font-medium text-gray-900">{{discipline.name}} <span (click)="rename(false)" class="ml-2 text-sm text-gray-600 font-semibold cursor-pointer underline">Rename</span></h1>
            }
            @else {
                <fc-text-input placeholder="" [(ngModel)]="discipline.name"></fc-text-input>
                <fc-button-primary (click)="rename(true)"><check-circle-outline-icon/></fc-button-primary>
            }
        </div> 
        <fc-button-secondary (click)="deleteDiscipline()">
            <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> Delete discipline
        </fc-button-secondary>
    </div>
    <div class="flex justify-between items-center  mt-6">
        <div class="inline-flex rounded-md shadow-sm">
            <button (click)="showRanks = true" type="button" [class.bg-gray-50]="showRanks" [class.bg-white]="!showRanks" class="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                Ranks
            </button>
            <button (click)="showRanks = false"  type="button" [class.bg-gray-50]="!showRanks" [class.bg-white]="showRanks" class="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                Progress
            </button>
        </div>
        @if (showRanks) {
        <div>
            <fc-button-secondary (click)="addRank()">
                <plus-outline-icon [size]="16"/> Add Rank
            </fc-button-secondary>
        </div>
        }
    </div>
    <form [formGroup]="settingsAlertsForm" (ngSubmit)="save()">
    @if (showRanks) {
    <div class="mt-6 border overflow-y-hidden rounded-lg">
        <table class="w-full">
            <thead class="bg-gray-50 text-gray-500 text-xs border-y select-none">
                <tr class="text-left">
                    <th scope="col" class="pl-6 py-3 font-medium">
                        Rank (lowest rank first, highest rank last)
                    </th>
                    <th scope="col" class="pl-6 py-3 font-medium">
                        
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let grade of discipline.grades; let first = first; let last = last; let i = index" 
                [class.border-t]="!first" class="hover:bg-gray-50 bg-white">
                    <td class="px-6 py-4">
                        {{grade.gradeName}}
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex gap-2 justify-end">
                            @if (!first) {
                            <arrow-up-outline-icon class="cursor-pointer" [size]="20" (click)="up(i)" />
                            }
                            @else {
                                <span class="w-[20px]"></span>
                            }
                            @if (!last) {
                            <arrow-down-outline-icon class="cursor-pointer" [size]="20" (click)="down(i)" />
                            }
                            @else {
                                <span class="w-[20px]"></span>
                            }
                            <pencil-outline-icon (click)="update(grade)" class="cursor-pointer" [size]="20"  />
                            <trash-outline-icon (click)="deleteRank(i)" class="cursor-pointer" [size]="20"  />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    }
    @else { 
    <div class="flex flex-col mt-6 gap-2 border-b pb-4">
        <div class="text-gray-900 font-semibold text-lg">Rank progression</div>
        <div class="text-gray-600 text-sm">Attendance requirement for students to progress their rank</div>
        <div class="flex mt-4 items-center w-full sm:w-1/2 justify-between">
            <div class="text-gray-700 font-semibold text-sm">Sessions attended:</div>
            <fc-text-input placeholder="" formControlName="visitsRequired" class="w-[75px]"></fc-text-input>
        </div>
        <div class="flex mt-4 items-center w-full sm:w-1/2 justify-between">
            <div class="text-gray-700 font-semibold text-sm max-w-[50%]">Private sessions are worth this many group sessions:</div>
            <fc-text-input placeholder="" formControlName="privateWeight"  class="w-[75px]"></fc-text-input>
        </div>
    </div>
    <div class="flex flex-col mt-6 gap-2">
        <div class="text-gray-900 font-semibold text-lg">Alert notifications</div>
        <div class="text-gray-600 text-sm">Get notified when a student is ready to be considered for grading</div>
        <div class="flex flex-col sm:flex-row pb-5 border-b gap-4 sm:gap-8 mt-4">
            <span class="text-gray-700 font-semibold text-sm">
                Receive alerts for:
            </span>
            <div class="flex flex-col gap-2 ">
                <div class="flex gap-6 items-center">
                    <input type="checkbox" formControlName="notifyMember"
                        class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                    <span class="text-gray-700 font-medium text-sm">
                        Members
                    </span>
                </div>
                <div class="flex gap-6 items-center">
                    <input type="checkbox" formControlName="notifyGuest"
                        class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                    <span class="text-gray-700 font-medium text-sm">
                        Guests (non-members)
                    </span>
                </div>
                <div class="flex gap-6 items-center">
                    <input type="checkbox" formControlName="notifyGradedStudent"
                        class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                    <span class="text-gray-700 font-medium text-sm">
                        Students you're managing rank
                    </span>
                </div>
                <div class="flex gap-6 items-center">
                    <input type="checkbox" formControlName="notifyNonGradedStudent"
                        class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
                    <span class="text-gray-700 font-medium text-sm">
                        Students you're not managing rank
                    </span>
                </div>
            </div>
        </div>
    </div>
    }
    
    <app-save-footer *ngIf="(touched && showRanks) || (settingsAlertsForm.dirty && !showRanks)" [buttonText]="'Save'" [loading]="submitting"></app-save-footer>
    </form>
}
@else {
<app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
}

<!-- <app-membership-create-form [membership]="membership" [disciplines]="disciplines" *ngIf="loaded"></app-membership-create-form> -->
