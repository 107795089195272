<div class="flex flex-col items-center justify-center h-full text-center py-24">
    <div *ngIf="hasIcon" class="mb-4 p-3 rounded-lg border border-gray-200 flex items-center justify-center text-gray-500">
        <ng-content select="[icon]"></ng-content>
    </div>
    <div *ngIf="!hasIcon" class="mb-4 p-3 rounded-lg border border-gray-200 flex items-center justify-center text-gray-500">
        <magnifying-glass-outline-icon [size]="24"/>
    </div>
    <h2 class="text-base font-semibold mb-2 text-gray-800">{{ heading }}</h2>
    <div class="text-sm text-gray-500 mb-6">
        <ng-content select="[description]"></ng-content>
    </div>
    <ng-content></ng-content>
</div>