import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() initials: string = '';
  @Input() imageUrl?: string;
  @Input() variant?: string = '';

  get classes(): string[] {
    const baseClasses = [
      'relative', 'inline-flex', 'items-center', 'justify-center', 'overflow-hidden', 'bg-gray-100', 'rounded-full', 'dark:bg-gray-600',
    ];

    if (this.variant == 'large') {
      baseClasses.push('w-16', 'h-16');
    } else {
      baseClasses.push('w-10', 'h-10');
    }

    return baseClasses;
  }

  get spanClasses(): string[] {
    const baseClasses = [
      'select-none', 'font-medium', 'text-gray-600', 'dark:text-gray-300'
    ]

    if (this.variant == 'large') {
      baseClasses.push('text-xl');
    }

    return baseClasses;
  }
}
