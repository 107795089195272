<div class="flex items-center md:mt-40 flex-col">
    <div class="border rounded-lg p-3 shadow-md shrink">
        <magnifying-glass-outline-icon [size]="30" stroke="2"></magnifying-glass-outline-icon>
    </div>
    <div class="font-semibold text-gray-900 mt-3">
        Add disciplines to track student progress!
    </div>
    <div class="text-gray-600 mt-1">
        Click new discipline to get started.
    </div>
    <div class="mt-6">
        <app-button-primary (onClick)="addDiscipline.emit()">
            <plus-outline-icon stroke="2" [size]="20" class="mr-2"></plus-outline-icon> New discipline
        </app-button-primary>
    </div>
</div>