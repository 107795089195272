import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'fc-ineligible-student-modal',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalComponent {
  constructor(@Inject(DIALOG_DATA) public data: { title: string, message: string, allowOverride: boolean}) {
  }
}
