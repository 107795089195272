export interface Document {
    id: number;
    name: string;
    description: string;
    isArchived: boolean;
    versions: DocumentVersion[];
    documentType: DocumentType;
}

export interface DocumentVersion {
    documentVersionId: number;
    documentId: number;
    content: string;
    version: number;
    createdAt: Date;
    signatureRequired: boolean;
}

export enum DocumentType {
    Student = 1,
    Membership = 2
}