import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrl: './settings-users.component.scss'
})
export class SettingsUsersComponent {
  @Input() users: User[] = [];

  loading: boolean = true;

  constructor(private userService: UserService) {

  }

  ngOnInit(): void {
    this.userService.getUsers().subscribe({
      next: users => {
        this.users = users;
        this.loading = false;
      }
    });
    
  }

  addUser() {
    // implemement with auth0
  }
}
