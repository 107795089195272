import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { Membership } from 'src/app/models/membership.model';

@Component({
  selector: 'app-memberships-grid',
  templateUrl: './memberships-grid.component.html',
  styleUrl: './memberships-grid.component.scss'
})
export class MembershipsGridComponent implements AfterViewInit {
  @Input() memberships: Membership[] = [];

  columns: GridColumn<Membership>[] = [];

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Name',
        field: 'name',
        classes: 'w-3/5'
      },
      {
        header: 'Version',
        formatter: (row) => row.versions[0].version.toString(),
        classes: 'text-center'
      },
      {
        header: 'Date',
        formatter: (row) => this.localDate(row.versions[0].createdAt),
      },
      {
        header: 'Active Members',
        field: 'activeCount',
        classes: 'text-center'
      },
    ];
    this.ref.detectChanges();
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }
}