import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-discipline-modal',
  templateUrl: './add-discipline-modal.component.html',
  styleUrl: './add-discipline-modal.component.scss',
})
export class AddDisciplineModalComponent {
  name: string | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string[], name: string }) {
    this.name = this.data.name;
  }
}
