<fc-grid [columns]="columns" [data]="memberships" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary [routerLink]="['edit', row.id]">
            View
        </fc-button-secondary>
    </div>
</ng-template>
