import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-disciplines-empty',
  templateUrl: './disciplines-empty.component.html',
  styleUrl: './disciplines-empty.component.scss'
})
export class DisciplinesEmptyComponent {
  @Output() addDiscipline = new EventEmitter();
}
