import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Discipline } from 'src/app/models/discipline.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-availability-create-form',
  templateUrl: './availability-create-form.component.html',
  styleUrl: './availability-create-form.component.scss'
})
export class AvailabilityCreateFormComponent implements OnInit {
  @Input() userId!: number;
  @Input() disciplines: Discipline[] = [];

  loading = true;
  submitted = false;
  submitting = false;
  availabilityForm!: FormGroup;

  daysOfWeek = [
    { id: 'monday', name: 'Monday' },
    { id: 'tuesday', name: 'Tuesday' },
    { id: 'wednesday', name: 'Wednesday' },
    { id: 'thursday', name: 'Thursday' },
    { id: 'friday', name: 'Friday' },
    { id: 'saturday', name: 'Saturday' },
    { id: 'sunday', name: 'Sunday' },
  ];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.availabilityForm = this.fb.group({
      availabilities: this.fb.array([]) // Start with an empty array
    });

    this.loadExistingAvailabilities(); // Load existing availabilities
  }

  get availabilities(): FormArray {
    return this.availabilityForm.get('availabilities') as FormArray;
  }

  getTimeRanges(availabilityIndex: number): FormArray {
    return this.availabilities.at(availabilityIndex).get('timeRanges') as FormArray;
  }

  getFormArrayControls(group: AbstractControl | null): AbstractControl[] {
    return (group as FormArray)?.controls || [];
  }
  

  createAvailabilityFormGroup(): FormGroup {
    return new FormGroup({
      disciplineIds: new FormControl<number[]>([], [Validators.required]),
      days: new FormControl<string[]>([], [Validators.required]),
      timeRanges: new FormArray([this.createTimeRangeFormGroup()])
    });
  }

  createTimeRangeFormGroup(): FormGroup {
    return this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required]
    });
  }

  loadExistingAvailabilities(): void {
    this.userService.getAvailability(this.userId).subscribe({
      next: (availabilities) => {
        if (availabilities && availabilities.length > 0) {
          availabilities.forEach((x) => this.addExistingAvailability(x));
        } else {
          this.addAvailability(); // Add a blank availability if none exist
        }
        this.availabilityForm.markAsPristine();
        this.loading = false;
      },
      error: (err) => {
        console.error('Failed to load availabilities:', err);
      }
    });

  }

  addExistingAvailability(availability: any): void {
    const { days, timeRanges } = availability.availabilitySchedule || { days: [], timeRanges: [] };
  
    const availabilityForm = this.fb.group({
      disciplineIds: new FormControl<number[]>(availability.disciplines.map((d: any) => d.id) || [], Validators.required),
      days: new FormControl<string[]>(days || [], Validators.required),
      timeRanges: this.fb.array(
        (timeRanges || []).map((range: any) =>
          this.fb.group({
            start: new FormControl<string>(range.start || '', Validators.required),
            end: new FormControl<string>(range.end || '', Validators.required)
          })
        )
      )
    });
  
    this.availabilities.push(availabilityForm);
  }

  addAvailability(): void {
    this.availabilities.push(this.createAvailabilityFormGroup());
    this.availabilityForm.markAsDirty();
  }

  removeAvailability(index: number): void {
    this.availabilities.removeAt(index);
  }

  addTimeRange(availabilityIndex: number): void {
    const timeRanges = this.getTimeRanges(availabilityIndex);
    timeRanges.push(this.createTimeRangeFormGroup());
    this.availabilityForm.markAsDirty();
  }

  removeTimeRange(availabilityIndex: number, rangeIndex: number): void {
    const timeRanges = this.getTimeRanges(availabilityIndex);
    timeRanges.removeAt(rangeIndex);
    if (timeRanges.length == 0) {
      this.removeAvailability(availabilityIndex)
    }
    this.availabilityForm.markAsDirty();
  }

  onSubmit(): void {
    this.submitted = true;

    if (!this.validateTimeRanges()) {
      return;
    }

    if (this.availabilityForm.valid) {
      this.submitting = true;

      const payload = this.availabilities.value.map((availability: any) => ({
        ...availability,
        availabilitySchedule: {
          days: availability.days,
          timeRanges: availability.timeRanges
        }
      }));

      payload.forEach((availability: any) => {
        delete availability.days;
        delete availability.timeRanges;
      });

      this.userService.UpdateAvailability(this.userId, payload).subscribe({
        next: () => {
          this.toasterService.addToaster({ type: ToasterType.Success, message: 'Availability updated successfully!' });
          this.submitting = false;
          this.submitted = false;
          this.availabilityForm.markAsPristine();
        },
        error: (err) => {
          this.toasterService.addToaster({ type: ToasterType.Error, message: err.error.message });
          this.submitting = false;
        }
      });
    }
  }

  private validateTimeRanges(): boolean {
    let isValid = true;
  
    this.availabilities.controls.forEach((availabilityGroup, availabilityIndex) => {
      const timeRanges = this.getTimeRanges(availabilityIndex);
      timeRanges.controls.forEach((timeRangeGroup: AbstractControl) => {
        const start = timeRangeGroup.get('start')?.value;
        const end = timeRangeGroup.get('end')?.value;
  
        if (!start || start.trim() === '') {
          isValid = false;
          timeRangeGroup.get('start')?.setErrors({ required: true });
        } else {
          timeRangeGroup.get('start')?.setErrors(null);
        }
        
        if (!end || end.trim() === '') {
          isValid = false;
          timeRangeGroup.get('end')?.setErrors({ required: true });
        } else {
          timeRangeGroup.get('end')?.setErrors(null);
        }
        
        if (start && end && start >= end) {
          isValid = false;
          timeRangeGroup.get('start')?.setErrors({ invalidRange: true });
          timeRangeGroup.get('end')?.setErrors({ invalidRange: true });
        } else if (start && end) {
          timeRangeGroup.get('start')?.setErrors(null);
          timeRangeGroup.get('end')?.setErrors(null);
        }
      });
    });
  
    return isValid;
  }

}