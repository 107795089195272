import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild, viewChild } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-users-grid',
  templateUrl: './users-grid.component.html',
  styleUrl: './users-grid.component.scss'
})
export class UsersGridComponent implements AfterViewInit {
  @Input() users: User[] = [];

  columns: GridColumn<User>[] = [];

  @ViewChild('nameTemplate', { static: true }) nameTemplate!: TemplateRef<any>;

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Name',
        template: this.nameTemplate
      },
      {
        header: 'Email',
        field: 'email',
      }
    ];
    this.ref.detectChanges();
  }

}
