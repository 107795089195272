import { Component, OnInit } from '@angular/core';
import { StudentNote } from 'src/app/models/student-note.model';
import { StudentService } from 'src/app/services/student.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NoteCreateModalComponent } from 'src/app/components/modals/note-create-modal/note-create-modal.component';
import { Student } from 'src/app/models/student.model';
import { forkJoin } from 'rxjs';
import { NoteService } from 'src/app/services/note.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ToasterType } from 'src/app/models/toaster.model';

@Component({
  selector: 'app-student-notes',
  templateUrl: './student-notes.component.html',
  styleUrls: ['./student-notes.component.scss']
})
export class StudentNotesComponent implements OnInit {
  studentId!: number;
  student!: Student;
  loading: boolean = true;
  studentNotes: StudentNote[] = [];
  constructor(private route: ActivatedRoute, private studentService: StudentService, private dialog: MatDialog, private noteService: NoteService, private toasterService: ToasterService){
  }

  ngOnInit(): void {
    const id = this.route.parent?.snapshot.params['id'];
    if (id) {
      this.studentId = +id;
      const notes = this.studentService.getStudentNotes(+id);
      const student = this.studentService.getStudent(+id);;
      forkJoin([notes, student])
      .subscribe(results => {
        this.studentNotes = results[0];
        this.student = results[1];
        this.loading = false;
      })
    }
  }

  addNote(): void {
    const dialogRef = this.dialog.open(NoteCreateModalComponent, {
      width: '400px',
      data: {students: [this.student]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.createNote({ studentId: this.studentId, content: result.content })
        .subscribe((note) => {
          this.studentNotes.unshift(note);
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Note created'});
        });
      }
    });
  }

  get manageNotesTitle(){
    return `Add training notes for ${this.student.firstName}!`
  }
}
