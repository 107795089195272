<ng-container *ngIf="loaded && studentGrades.length == 0">
    <fc-empty-grid
        [heading]="manageTrainingTitle">
        <p description>Click add discipline to get started.</p>
        <fc-button-primary variant="large" (click)="addTraining()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span>Add discipline</span>
        </fc-button-primary>
    </fc-empty-grid>
</ng-container>
<ng-container *ngIf="studentGrades.length > 0">
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        <fc-button-primary *ngIf="canAddDisciplines" (click)="addTraining()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add discipline</span>
            <span class="flex sm:hidden">Add</span>
        </fc-button-primary>
    </div>
    <div class="w-full mt-8">
        <div *ngFor="let grade of studentGrades" class="mb-6">
            <app-training-card [grade]="grade" [gradeOptions]="getGradesByDiscipline(grade.disciplineId)" [alertSettings]="getAlertSettings(grade.disciplineId)"></app-training-card>
        </div>
    </div>
</ng-container>
