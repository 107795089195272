import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { GymService } from 'src/app/services/gym.service';
import { StudentService } from 'src/app/services/student.service';
import { StudentGrade } from 'src/app/models/student-grade.model';
import { GradingAlertSettings } from 'src/app/models/gym-alerts-grading.model';

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss']
})
export class TrainingCardComponent implements OnInit {
  @Input() grade!: StudentGrade;
  @Input() gradeOptions!: any[];
  @Input() alertSettings: GradingAlertSettings | undefined;

  loaded: boolean = false;
  expanded: boolean = true;
  submitted: boolean = false;
  editing: boolean = false;
  visitsRequired: number | undefined = 1;
  privateWeight: number | undefined = 1;
  totalProgression: number = 0;
  groupProgression: number | undefined;
  privateProgression: number | undefined;
  trainingCardForm = new FormGroup({
    manageGrade: new FormControl<boolean>(false, Validators.required),
    date: new FormControl<string>('', Validators.required),
    gradeId: new FormControl<number | null>(null, Validators.required)
  });

  constructor(private fb: FormBuilder, private studentService: StudentService, private gymService: GymService) { }

  ngOnInit(): void {
    if (this.grade) {
      this.setGradeId(this.grade.gradeId);
      this.setManageGrade(this.grade.manageGrade);
      this.setDate(this.grade.gradeDate);
      this.visitsRequired = this.alertSettings?.visitsRequired ?? 65;
      this.privateWeight = this.alertSettings?.privateWeight ?? 1;
      if (this.visitsRequired && this.privateWeight) {
        this.groupProgression = (this.grade.progress?.groupCurrent / Math.max(this.visitsRequired, 1)) * 100;
        this.privateProgression = ((this.grade.progress?.privateCurrent * this.privateWeight) / Math.max(this.visitsRequired, 1)) * 100;
        this.totalProgression = this.groupProgression + this.privateProgression;
        // cap at 100% and make each bar proportional
        if (this.totalProgression > 100) {
          this.groupProgression = (this.groupProgression / this.totalProgression) * 100;
          this.privateProgression = (this.privateProgression / this.totalProgression) * 100;
        }
      }
    }
  }

  get date() {
    return this.trainingCardForm.get('date')?.value;
  }

  get manageGrade() {
    return this.trainingCardForm.get('manageGrade')?.value;
  }

  get gradeName() {
    const gradeId = this.trainingCardForm.get('gradeId')?.value

    if (!gradeId) {
      return;
    }
    return this.gradeOptions.find(x => x.gradeId == gradeId)?.gradeName;
  }


  setGradeId(value: number) {
    this.trainingCardForm.get('gradeId')?.setValue(value);
  }

  setManageGrade(value: boolean) {
    this.trainingCardForm.get('manageGrade')?.patchValue(value);
  }

  setDate(value: string) {
    this.trainingCardForm.get('date')?.setValue(value);
  }

  dateChange(date: MatDatepickerInputEvent<Date>) {
    if (date?.value) {
      const timezoneOffsetMinutes = date.value.getTimezoneOffset();
      const adjustedDate = new Date(date.value.getTime() - (timezoneOffsetMinutes * 60 * 1000));
      const localISOString = adjustedDate.toISOString();
      this.setDate(localISOString.split('T')[0]);
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  onSubmit() {
    this.submitted = true;
    if (this.trainingCardForm.valid) {
      this.studentService.updateStudentGrade(this.grade.studentId, this.grade.id, this.trainingCardForm.value).subscribe(() => { });
      this.trainingCardForm.markAsPristine();
      this.submitted = false;
    }
  }
}
