<div class="mb-8" *ngIf="alerts.length > 0">
    <mat-expansion-panel class="w-full font-medium text-left p-0 text-sm text-gray-800 border border-gray-300 bg-gray-50">
        <mat-expansion-panel-header>
            <mat-panel-title class="flex justify-between">
                    <div>
                    <span class="h-5 w-5 inline-block text-center border border-red-800 bg-red-100 text-red-800 text-sm font-medium rounded-full mr-1">
                        {{alerts.length}}
                    </span>
                        {{alerts.length > 1 ? 'Alerts' : 'Alert' }}
                    </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let alert of alerts" class="w-full text-left px-4 py-4 text-sm text-gray-800 border-b border-gray-300 last:border-0 last:mb-0">
            <div class="mb-2">
                <span *ngIf="alert.type === alertType.Grade">
                    <span class="font-medium text-black">
                        {{alert.firstName}} {{alert.lastName}} ({{alert.discipline}} - {{alert.grade}}):
                    </span> 
                    for
                    <span class="font-medium text-black">{{getTime(alert.sessionDate)}}</span>
                    with
                    <span *ngIf="alert.privateCount && alert.privateCount > 0 && alert.groupCount && alert.groupCount > 0">
                        <span class="font-medium text-black">{{alert.groupCount}} groups</span>
                        and
                        <span class="font-medium text-black">{{alert.privateCount}} privates.</span>
                    </span>
                    <span *ngIf="alert.privateCount && alert.privateCount > 0 && alert.groupCount && alert.groupCount <= 0">
                        <span class="font-medium text-black">{{alert.privateCount}} privates.</span>
                    </span>
                    <span *ngIf="alert.privateCount && alert.privateCount <= 0 && alert.groupCount && alert.groupCount > 0">
                        <span class="font-medium text-black">{{alert.groupCount}} groups.</span>
                    </span>
                    Consider assessing their grade.
                </span>
                <span *ngIf="alert.type === alertType.Attendance">
                    <span class="font-medium text-black">
                        {{alert.firstName}} {{alert.lastName}} ({{alert.discipline}} - {{alert.grade}}):
                    </span>
                    Hasn't visited for <span class="font-medium text-black">{{getTime(alert.sessionDate)}}</span>.
                    <span *ngIf="alert.mobile && alert.email">
                        Re-engage with them at <a class="underline font-medium" [href]="'mailto:' + alert.email">{{alert.email}}</a> or <a class="underline font-medium" [href]="'tel:' + alert.mobile">{{alert.mobile}}</a>
                    </span>
                    <span *ngIf="alert.mobile && !alert.email">
                        Re-engage with them at <a class="underline font-medium" [href]="'tel:' + alert.mobile">{{alert.mobile}}</a>
                    </span>
                    <span *ngIf="!alert.mobile && alert.email">
                        Re-engage with them at <a class="underline font-medium" [href]="'mailto:' + alert.email">{{alert.email}}</a>
                    </span>
                </span>
                <span *ngIf="alert.type === alertType.Document">
                    <span class="font-medium text-black">
                        {{alert.firstName}} {{alert.lastName}}:
                    </span> 
                    Has unsigned documents.
                </span>
                <span *ngIf="alert.type === alertType.IneligibleStudent">
                    <span class="font-medium text-black">
                        {{alert.firstName}} {{alert.lastName}}:
                    </span> 
                    Attempted to attend a session for {{alert.discipline}} but they have no eligible memberships.
                </span>
                <span *ngIf="alert.type === alertType.MembershipPaymentFailure">
                    <span class="font-medium text-black">
                        {{alert.firstName}} {{alert.lastName}}:
                    </span> 
                    Payment failed for membership {{alert.membership}}.
                </span>
                <span *ngIf="alert.type === alertType.MembershipLinkExpiry">
                    <span class="font-medium text-black">
                        {{alert.firstName}} {{alert.lastName}}:
                    </span> 
                    {{alert.membership}} invitation was not accepted by {{alert.firstName}} within 24 hours and has expired.
                </span>
            </div>
            <div class="hidden sm:flex justify-between items-center">
                <div>
                    <a [routerLink]="['student', alert.studentId, 'general']" class="inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg px-2.5 py-2">View Student</a>
                    <button *ngIf="alert.type !== alertType.Document && alert.type !== alertType.IneligibleStudent && alert.type !== alertType.MembershipPaymentFailure && alert.type !== alertType.MembershipLinkExpiry" (click)="onRemind.emit(alert.id)" class="inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg px-2.5 py-2 ml-2">Remind Me</button>
                </div>
                <div>
                    <button (click)="onDismiss.emit(alert.id)" class="inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg px-2.5 py-2 ml-2">Dismiss</button>
                </div>
            </div>
            <div class="flex sm:hidden flex-col items-stretch space-y-2">
                <a [routerLink]="['student', alert.studentId, 'general']" class="text-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg px-2.5 py-2">View Student</a>
                <button *ngIf="alert.type !== alertType.Document && alert.type !== alertType.IneligibleStudent && alert.type !== alertType.MembershipPaymentFailure && alert.type !== alertType.MembershipLinkExpiry" (click)="onRemind.emit(alert.id)" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg px-2.5 py-2">Remind Me</button>
                <button (click)="onDismiss.emit(alert.id)" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg px-2.5 py-2">Dismiss</button>
            </div>
        </div>
    </mat-expansion-panel>
</div>