import { Component, Input, Output, EventEmitter, AfterContentInit, ContentChild } from '@angular/core';
import { BaseOutlineIconComponent } from '@dimaslz/ng-heroicons/components/common/base-outline-icon.component';

@Component({
  selector: 'fc-empty-grid',
  templateUrl: './empty-grid.component.html',
})
export class EmptyGridComponent implements AfterContentInit {
  @Input() heading: string = 'No Records Found';
  @Input() description: string = 'It looks like there are no records here yet.';
  @Input() buttonText: string = 'Create New';
  @Output() actionClick = new EventEmitter<void>();

  @ContentChild('icon', { static: false }) iconComponent?: BaseOutlineIconComponent;
  hasIcon = false;

  constructor() {}

  onActionClick() {
    this.actionClick.emit();
  }

  ngAfterContentInit(): void {
    this.hasIcon = !!this.iconComponent;
  }
}
