<form [formGroup]="studentDocumentCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-8 mb-8">
        <div class="flex flex-col gap-5 items-start">
            <div class="w-full ">
                {{ studentDocument?.name + ' - Version ' + studentDocument?.version }}
            </div>
            <div class="w-full h-full py-2.5 border border-gray-200 rounded-lg bg-white"
                [innerHTML]="studentDocument?.content">
            </div>
            <div class="w-full bg-white">
                <canvas class="bg-white"  #canvas></canvas>
            </div>
        </div>
    </div>
    <app-save-footer *ngIf="this.studentDocumentCreateForm.dirty" [buttonText]="'Submit document'" [error]="submitted && !studentDocumentCreateForm.valid"></app-save-footer>
</form>
