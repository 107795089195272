<fc-grid [columns]="columns" [data]="disciplines" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary [routerLink]="[row.id]">
            View
        </fc-button-secondary>
    </div>
</ng-template>

<!-- Example of using a custom template for cell -->
<ng-template #nameTemplate let-row>
    <span>{{ row.name }}</span>
</ng-template>