import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StudentNote } from '../models/student-note.model';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(private http: HttpClient) {
  }

  createNote(note: any) {
    return this.http.post<StudentNote>(`${environment.baseUrl}/note`, note);
  }
}
