import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { StudentNote } from 'src/app/models/student-note.model';

@Component({
  selector: 'app-student-notes-grid',
  templateUrl: './student-notes-grid.component.html',
  styleUrl: './student-notes-grid.component.scss'
})
export class StudentNotesGridComponent implements AfterViewInit {
  @Input() notes: StudentNote[] = [];

  columns: GridColumn<StudentNote>[] = [];

  @ViewChild('contentTemplate', { static: true }) contentTemplate!: TemplateRef<any>;

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Source',
        formatter: (row) => {
          const noteType = row.sessionInstanceId ? 'Private session' : 'General note'
          const createdBy = row.userId ? `created by ${row.userFirstName} ${row.userLastName}` : '';
          return [noteType, createdBy].join(' ').trim()
        },
        classes: 'whitespace-nowrap overflow-hidden text-ellipsis align-top',
      },
      {
        header: 'Date',
        formatter: (row) => this.localDate(row.date),
        classes: 'align-top',
      },
      {
        header: 'Content',
        template: this.contentTemplate,
      },
    ];
    this.ref.detectChanges();
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }
}