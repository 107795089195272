@if (loading) {
    <app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
} 
@else if (users.length == 0) {
    <fc-empty-grid
        heading="No Users Found">
        <p description>Contact FightCloud support.</p>
    </fc-empty-grid>
}
@else {
    <!-- <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        <fc-button-primary (click)="addUser()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add user</span>
            <span class="flex sm:hidden">Add</span>
        </fc-button-primary>
    </div> -->
    <app-users-grid [users]="users"></app-users-grid>
}