<div class="bg-error-50">
    <h1 class="text-lg font-semibold text-error-900 mb-2 pt-4 px-4">
        {{data.title}}
    </h1>
    <div class="px-4 text-sm text-error-900">
        {{data.message}}
    </div>
    <div class="flex pt-4 pb-6 px-6 gap-3 justify-end">
        <fc-button-secondary *ngIf="data.allowOverride" [mat-dialog-close]="true">Override</fc-button-secondary>
        <button class="bg-error-700 w-20 text-white border border-error-300 hover:bg-error-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg px-3 py-1.5 text-center" [mat-dialog-close]="false">Ok</button>
    </div>
</div>